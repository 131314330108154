import { FunctionComponent, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { FieldHookConfig, useField } from 'formik';
import Box from 'src/rewardis-kit/components/box';
import Button from 'src/rewardis-kit/components/button';
import * as yup from 'yup';
import GriffelBasedModal from 'src/components/GriffelBasedModal';

import {
  PrivacyAgreementFieldProps,
  REJECTED_ANY,
} from '../PrivacyAgreementField';
import PrivacyAgreementField from './PrivacyAgreementField';
import TCSettings from 'src/components/TermsConditionSettings/TCSettings';
import PrivacyAgreementModalText from './PrivacyAgreementModalText';
import { ValidationSchemaProps } from 'src/components/forms/RegistationForm/typings';
import useClasses from './get-styles';

export const initialValue = false;
export const createValidationSchema = ({ intl }: ValidationSchemaProps) =>
  yup
    .boolean()
    .required(
      intl.formatMessage({
        id: 'form.validation.required.privacyAgreement',
        defaultMessage: 'This field is required',
      })
    )
    .oneOf(
      [true],
      intl.formatMessage({
        id: 'form.validation.required.privacyAgreement',
        defaultMessage: 'This field is required',
      })
    );

export const name = 'privacy_agreement';

export const Component: FunctionComponent<
  PrivacyAgreementFieldProps & FieldHookConfig<boolean>
> = ({ onPrivacyLinkClick, onTermsLinkClick, onMarketingLinkClick, name }) => {
  const [isOpen, setOpen] = useState(true);
  const [isShowSettings, setShowSettings] = useState(false);
  const [iShowCheckbox, setShowCheckbox] = useState(false);
  const [, , helper] = useField(REJECTED_ANY);
  const [field, meta, mainFieldHelper] = useField(name);
  const intl = useIntl();
  const styles = useClasses();
  const onClose = useCallback(() => setOpen(false), []);

  const settingsCallback = useCallback(
    (isRejectedAny: boolean, isRejectedAll?: boolean) => {
      onClose();
      if (isRejectedAll) {
        setShowCheckbox(true);
      } else {
        mainFieldHelper.setValue(true);
      }

      helper.setValue(isRejectedAny || undefined);
    },
    []
  );

  return (
    <>
      {iShowCheckbox && (
        <PrivacyAgreementField
          field={field}
          meta={meta}
          onPrivacyLinkClick={onPrivacyLinkClick}
          onTermsLinkClick={onTermsLinkClick}
        />
      )}
      <GriffelBasedModal
        isOpen={isOpen}
        width={'600px'}
        fullWidth
        handleClose={onClose}
        title={intl.formatMessage({
          id: 'tc_settings_modal.header',
          defaultMessage: 'Advertising Media Channels Settings',
        })}
      >
        {isShowSettings ? (
          <>
            <TCSettings
              onSave={settingsCallback}
              handleMarketingClick={onMarketingLinkClick}
            />
            <Button
              size={'lg'}
              className={styles.rejectButton}
              onClick={() => settingsCallback(true, true)}
            >
              {intl.formatMessage({
                id: 'tc_settings_modal.reject_all',
                defaultMessage: 'Reject all',
              })}
            </Button>
          </>
        ) : (
          <Box className={styles.buttons}>
            <PrivacyAgreementModalText
              onMarketingLinkClick={onMarketingLinkClick}
              onOpenSettingsClick={() => setShowSettings(true)}
              onTermsLinkClick={onTermsLinkClick}
              onPrivacyLinkClick={onPrivacyLinkClick}
            />
            <Button
              size={'lg'}
              className={styles.primaryButton}
              onClick={() => settingsCallback(false)}
              color="primary"
              variant="contained"
            >
              {intl.formatMessage({
                id: 'tc_settings_modal.save_all',
                defaultMessage: 'Accept all and continue',
              })}
            </Button>
            <Button size={'lg'} onClick={() => setShowSettings(true)}>
              {intl.formatMessage({
                id: 'tc_settings_modal.settings',
                defaultMessage: 'Settings',
              })}
            </Button>
          </Box>
        )}
      </GriffelBasedModal>
    </>
  );
};
