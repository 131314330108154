import Box from 'src/rewardis-kit/components/box';
import Typography from 'src/rewardis-kit/components/typography';
import { useIntl } from 'react-intl';

import { reviewListKey } from './data';
import useClasses from './get-styles';
import { TypographyVariants } from 'src/rewardis-kit/components/typography/types';

const Reviews = () => {
  const intl = useIntl();
  const styles = useClasses();
  return (
    <Box className={styles.reviewsWrapper}>
      {reviewListKey.map((review, index) => (
        <Box className={styles.review} key={index}>
          <Typography
            variant={TypographyVariants.body2}
            className={styles.reviewText}
          >
            {intl.formatMessage(review.text)}
          </Typography>
          <Typography
            variant={TypographyVariants.body2}
            className={styles.reviewName}
          >
            {intl.formatMessage(review.name)}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default Reviews;
