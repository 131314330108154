import Modal from 'src/components/Modal';
import Response from 'src/components/Response';
import { RemoteData } from 'src/lib/remoteData';
import {
  Chip,
  FormControlLabel,
  FormGroup,
  Link,
  Stack,
  Switch,
} from '@mui/material';

import Typography from 'src/rewardis-kit/components/typography';
import Box from 'src/rewardis-kit/components/box';

import { useIntl } from 'react-intl';
import { Fragment, memo, ReactElement, useCallback } from 'react';
import { TypographyVariants } from 'src/rewardis-kit/components/typography/types';
import useClasses from './get-styles';

interface RegistrationPartnerModalProps {
  partnerRequest: RemoteData<Partner[]>;
  selectedPartnerIds: number[];
  onPartnerSelect: (isChecked: boolean, partner: Partner) => void;
  handleClose: () => void;
  isOpen: boolean;
}

const NoDataHandler = () => (
  <div>
    {useIntl().formatMessage({
      id: 'no_partners',
      defaultMessage: 'There is no partners for you',
    })}
  </div>
);

const PartnersList = memo(
  ({
    partners,
    selectedPartnerIds,
    onPartnerSelect,
  }: Pick<
    RegistrationPartnerModalProps,
    'onPartnerSelect' | 'selectedPartnerIds'
  > & { partners: Partner[] }): ReactElement[] => {
    const styles = useClasses();

    return partners.map((partner, i) => (
      <Fragment key={i}>
        <Box className={styles.wrapper}>
          <Box key={i} className={styles.partnerWrapper}>
            <Typography
              variant={TypographyVariants.h5}
              paragraph
              className={styles.headerName}
            >
              {partner.name}
            </Typography>
            <Stack direction="row" spacing={1}>
              {partner.marketings?.map((marketing, j) => (
                <Chip key={j} label={marketing} />
              ))}
            </Stack>
            <Box
              className={styles.text}
              dangerouslySetInnerHTML={{
                __html: partner.info.replace(/(\r)?\n/g, '<br />'),
              }}
            />
            <Link
              sx={{ paddingY: 1 }}
              href={partner.policy_url}
              target="_blank"
            >
              {partner.policy_url}
            </Link>
            <FormGroup sx={{ marginY: 1, alignItems: 'flex-start' }}>
              <FormControlLabel
                labelPlacement="start"
                label={useIntl().formatMessage({
                  id: 'disableEnable',
                  defaultMessage: 'Disable/Enable',
                })}
                sx={{ margin: 0 }}
                control={
                  <Switch
                    checked={selectedPartnerIds.includes(partner.id)}
                    onChange={(e) => onPartnerSelect(e.target.checked, partner)}
                  />
                }
              />
            </FormGroup>
          </Box>
        </Box>
      </Fragment>
    ));
  }
);

export default function RegistrationPartnerModal(
  props: RegistrationPartnerModalProps
) {
  const {
    isOpen,
    handleClose,
    partnerRequest,
    selectedPartnerIds,
    onPartnerSelect,
  } = props;
  const intl = useIntl();

  const getPartnersList = useCallback(
    (partners: Partner[]) => (
      <>
        <Typography variant={TypographyVariants.h4}>
          {intl.formatMessage({
            id: 'ourPartners',
            defaultMessage: 'Our partners',
          })}
        </Typography>
        <PartnersList
          onPartnerSelect={onPartnerSelect}
          selectedPartnerIds={selectedPartnerIds}
          partners={partners}
        />
      </>
    ),
    [onPartnerSelect, selectedPartnerIds]
  );

  return (
    <Modal withClose fullWidth isOpen={isOpen} handleClose={handleClose}>
      <Response
        NotAskedView={NoDataHandler}
        EmptyView={NoDataHandler}
        request={partnerRequest}
        children={getPartnersList}
      />
    </Modal>
  );
}
