export const LANDING_ID = {
  OLD: 1,
  NEW: 2,
  OLD_FAST: 3,
  SHORT_FORM: 4,
  NEW_GOOGLE_AUTH_BOTTOM_AND_TC_MODAL: 21,
  NEW_GOOGLE_AUTH_TOP_AND_TC_MODAL: 22,
  NEW_REMOVED_STREAM: 23,
  NEW_REMOVED_STREAM_AND_GOOGLE_AUTH_TOP: 24,
  NEW_REMOVED_STREAM_AND_GOOGLE_AUTH_TOP_AND_TC_MODAL: 25,
  NEW_NEW: 27,
  HIDE_GOOGLE_AUTH_PART_DATA: 28,
  HIDE_GOOGLE_AUTH_FULL_DATA: 29,
};

export enum GOOGLE_BUTTON_POSITION {
  NONE,
  TOP,
  CENTER,
  BOTTOM,
}

export const isModalTC = (designId?: number): boolean => {
  if (!designId) {
    return true;
  }

  return [
    LANDING_ID.NEW_REMOVED_STREAM_AND_GOOGLE_AUTH_TOP_AND_TC_MODAL,
    LANDING_ID.NEW_GOOGLE_AUTH_BOTTOM_AND_TC_MODAL,
    LANDING_ID.NEW_GOOGLE_AUTH_TOP_AND_TC_MODAL,
    LANDING_ID.HIDE_GOOGLE_AUTH_FULL_DATA,
    LANDING_ID.HIDE_GOOGLE_AUTH_PART_DATA,
    LANDING_ID.NEW_NEW,
    LANDING_ID.SHORT_FORM,
    LANDING_ID.NEW,
    LANDING_ID.OLD,
    LANDING_ID.OLD_FAST,
  ].includes(designId);
};

export const getGoogleButtonPosition = (
  designId: number
): GOOGLE_BUTTON_POSITION => {
  switch (designId) {
    case LANDING_ID.NEW_GOOGLE_AUTH_BOTTOM_AND_TC_MODAL:
      return GOOGLE_BUTTON_POSITION.BOTTOM;
    case LANDING_ID.NEW_GOOGLE_AUTH_TOP_AND_TC_MODAL:
    case LANDING_ID.NEW_REMOVED_STREAM_AND_GOOGLE_AUTH_TOP:
    case LANDING_ID.NEW_REMOVED_STREAM_AND_GOOGLE_AUTH_TOP_AND_TC_MODAL:
    case LANDING_ID.NEW_NEW:
    case LANDING_ID.SHORT_FORM:
    case LANDING_ID.NEW:
    case LANDING_ID.OLD:
    case LANDING_ID.OLD_FAST:
    case LANDING_ID.HIDE_GOOGLE_AUTH_FULL_DATA:
    case LANDING_ID.HIDE_GOOGLE_AUTH_PART_DATA:
      return GOOGLE_BUTTON_POSITION.TOP;
    case LANDING_ID.NEW_REMOVED_STREAM:
      return GOOGLE_BUTTON_POSITION.CENTER;
    default:
      return GOOGLE_BUTTON_POSITION.BOTTOM;
  }
};

export const isHideGoogleAuthButton = (designId: number): boolean => {
  return [
    LANDING_ID.HIDE_GOOGLE_AUTH_PART_DATA,
    LANDING_ID.HIDE_GOOGLE_AUTH_FULL_DATA,
  ].includes(designId);
};

export const BLUE_DESIGN_ID = [
  LANDING_ID.NEW_GOOGLE_AUTH_BOTTOM_AND_TC_MODAL,
  LANDING_ID.NEW_GOOGLE_AUTH_TOP_AND_TC_MODAL,
  LANDING_ID.NEW_REMOVED_STREAM,
  LANDING_ID.NEW_REMOVED_STREAM_AND_GOOGLE_AUTH_TOP,
  LANDING_ID.NEW_REMOVED_STREAM_AND_GOOGLE_AUTH_TOP_AND_TC_MODAL,
];

export const GREEN_DESIGN_ID = [
  LANDING_ID.SHORT_FORM,
  LANDING_ID.NEW_NEW,
  LANDING_ID.NEW,
  LANDING_ID.OLD,
  LANDING_ID.OLD_FAST,
  LANDING_ID.HIDE_GOOGLE_AUTH_PART_DATA,
  LANDING_ID.HIDE_GOOGLE_AUTH_FULL_DATA,
];
