import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Modal from 'src/components/Modal';
import { Box, Typography } from '@mui/material';
import boxImage from 'src/assets/box/box.png';
import boxRibbonImage from 'src/assets/box/ribbon.png';

interface RegistrationBackButtonModalProps {
  handleClick: () => void;
  handleClose: () => void;
  isOpen: boolean;
}

export default function RegistrationBackButtonModal(
  props: RegistrationBackButtonModalProps
) {
  const intl = useIntl();
  const { handleClick, handleClose, isOpen } = props;
  const [opacity, setOpacity] = useState<number>(0);
  const [rotate, setRotate] = useState<number>(0);
  const INTERVAL_OPACITY: number = 2000;
  const INTERVAL_ROTATE: number = 100;

  useEffect(() => {
    let counterOpacity: number = 0;
    let counterRotate: number = 0;

    const intervalOpacity = setInterval(() => {
      setOpacity(++counterOpacity % 2 === 0 ? 0 : 1);
    }, INTERVAL_OPACITY);

    const intervalAnimationRotate = setInterval(() => {
      if (++counterRotate % 100 < 30) {
        setRotate(counterRotate % 2 === 0 ? -2 : 2);
      }
    }, INTERVAL_ROTATE);

    return () => {
      clearInterval(intervalOpacity);
      clearInterval(intervalAnimationRotate);
    };
  }, []);

  return (
    <Modal
      withClose
      width={313}
      isOpen={isOpen}
      handleClose={handleClose}
      paperStyles={{
        boxShadow: 'none',
        background: 'transparent',
      }}
      paperSx={() => ({
        '& > .MuiBox-root': {
          right: '35px',
          top: '30px',
        },
      })}
    >
      <Box
        onClick={handleClick}
        sx={{
          background: '#fff',
          borderRadius: '16px',
          padding: '16px',
          textAlign: 'center',
        }}
      >
        <Box
          sx={{
            height: '117px',
            margin: 'auto',
            position: 'relative',
            transform: `translate(0, -36px) rotate(${rotate}deg)`,
            transition: 'transform 0.1s',
            width: '121px',
          }}
        >
          <Box
            component="img"
            alt="Magic box"
            src={boxImage}
            sx={{
              height: '117px',
              left: 0,
              position: 'absolute',
              width: '121px',
              zIndex: '1',
            }}
          />
          <Box
            component="img"
            alt="Magic box"
            src={boxRibbonImage}
            sx={{
              filter: `brightness(1.35)`,
              height: '117px',
              left: 0,
              margin: 'auto',
              position: 'absolute',
              right: 'auto',
              opacity: `${opacity}`,
              transform: 'translate(24px, 0)',
              transition: 'opacity 2s',
              width: '79px',
              zIndex: '2',
            }}
          />
        </Box>
        <Box sx={{ marginBottom: '24px' }}>
          <Typography
            sx={{
              color: '#11B479',
              lineHeight: '24px',
              fontSize: 20,
              fontWeight: 600,
            }}
          >
            {intl.formatMessage({
              id: 'RegistrationPage.BackButtonModalTitle',
              defaultMessage: 'Free Gift! 🎉',
            })}
          </Typography>
          <Typography
            sx={{
              lineHeight: '24px',
              fontSize: 20,
              fontWeight: 600,
            }}
          >
            {intl.formatMessage({
              id: 'RegistrationPage.BackButtonModalSubTitle',
              defaultMessage: 'Thank you for your participation',
            })}
          </Typography>
        </Box>
        <Typography
          sx={{
            lineHeight: '19px',
            fontSize: 13,
            fontWeight: 400,
          }}
        >
          {intl.formatMessage({
            id: 'RegistrationPage.BackButtonModalDescription',
            defaultMessage:
              'Click the gift box to reveal your exclusive welcome gift.',
          })}
        </Typography>
      </Box>
    </Modal>
  );
}
