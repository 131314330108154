import React, { FC, memo, ReactElement, useCallback } from 'react';
import { CoreSelectorProps, Option } from './types';

export const OptionsComponents = <T,>({
  options,
  RenderOption,
  handleOptionClick,
  selectedOptions,
}: {
  options: Option<T>[];
  handleOptionClick: (option: Option<T>) => void;
  selectedOptions: Option<T>[];
  RenderOption?: CoreSelectorProps<T>['RenderOption'];
}): ReactElement[] => {
  return options.map((option) => {
    const handleClick = () => handleOptionClick(option);
    return RenderOption ? (
      <RenderOption
        {...option}
        key={option.id}
        onClick={handleClick}
        selectedOptions={selectedOptions}
      />
    ) : (
      <div key={option.id} onClick={handleClick}>
        {option.label}
      </div>
    );
  });
};

export const Options = memo(
  OptionsComponents
) as unknown as typeof OptionsComponents;
