import React, { FC, memo, useMemo } from 'react';
import { ButtonProps } from 'src/rewardis-kit/components/button/types';
import { useClasses } from 'src/rewardis-kit/components/button/get-styles';
import { mergeClasses } from '@griffel/react';

const ButtonComponent: FC<ButtonProps> = ({
  children,
  className,
  color,
  size,
  variant,
  iconAfter,
  iconBefore,
  ...rest
}) => {
  const classes = useClasses();
  const classesMap: Record<string, string> = useMemo(
    () => ({
      primary: classes.withPrimaryColor,
      secondary: classes.withSecondaryColor,
      lg: classes.withLgSize,
      sm: classes.withSmSize,
      outlined: classes.withOutlinedVariant,
      text: classes.withTextVariant,
    }),
    [classes]
  );

  const colorClass = classesMap[color as string] || '';
  const sizeClass = classesMap[size as string] || classes.withMdSize;
  const variantClass =
    classesMap[variant as string] || classes.withContainedVariant;

  return (
    <button
      className={mergeClasses(
        classes.base,
        colorClass,
        sizeClass,
        variantClass,
        className
      )}
      {...rest}
    >
      {iconBefore && (
        <span className={mergeClasses(classes.iconBase, classes.iconLeft)}>
          {iconBefore}
        </span>
      )}
      {children}
      {iconAfter && (
        <span className={mergeClasses(classes.iconBase, classes.iconRight)}>
          {iconAfter}
        </span>
      )}
    </button>
  );
};

export default memo(ButtonComponent);
