import { makeStyles, shorthands } from '@griffel/react';

export default makeStyles({
  layoutWrapper: {
    backgroundColor: '#07091D',
    '@media (mix-width: 900px)': {
      fontSize: '12px',
    },
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  layoutHeaderWrapper: {
    paddingY: '24px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    alignItems: 'center',
    maxWidth: '1200px',
    '@media (min-width: 900px)': {
      justifyContent: 'space-between',
      paddingY: '16px',
    },
  },
  layoutHeaderLogo: {
    '@media (max-width: 900px)': {
      display: 'none',
    },
  },
  layoutHeaderLogoMobile: {
    '@media (min-width: 900px)': {
      display: 'none',
    },
  },
  layoutHeaderLangSwitcher: {
    top: '24px',
    right: '24px',
    display: 'inline-flex',
    '& button': {
      ...shorthands.margin('auto'),
    },
    '@media (max-width: 900px)': {
      position: 'absolute',
      height: '32px',
    },
  },
});
