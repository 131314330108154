import {
  TypographyProps,
  TypographyVariants,
} from 'src/rewardis-kit/components/typography/types';
import { GriffelStyle, makeStyles } from '@griffel/react';

export const getStyles = (props?: TypographyProps): GriffelStyle => ({
  color: props ? '' : 'var(--typography--color)',
  textAlign: props?.align || 'inherit',
  fontFamily: props?.fontFamily || 'var(--typography--font-family)',
  display: props?.paragraph ? 'block' : 'inline',
  whiteSpace: props?.noWrap ? 'nowrap' : 'normal',
  marginBottom: props?.gutterBottom ? '0.35em' : '',
});

const generateVariantsStyles = () =>
  Object.values(TypographyVariants).reduce(
    (acc, item) => {
      acc[item as TypographyVariants] = {
        fontWeight: `var(--typography-${item}-font-weight)`,
        fontSize: `var(--typography-${item}-font-size)`,
        letterSpacing: `var(--typography-${item}-letter-spacing)`,
        lineHeight: `var(--typography-${item}-line-height)`,
      };
      return acc;
    },
    {} as Record<TypographyVariants, GriffelStyle>
  );

export const useClasses = makeStyles({
  root: getStyles(),
});

export const useSpecifiedClasses = makeStyles({
  ...generateVariantsStyles(),
});
