import { makeStyles, shorthands } from '@griffel/react';

export default makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    ...shorthands.gap('12px'),
    alignItems: 'center',
  },
  labelContainer: {
    ...shorthands.flex(1),
    '@media screen and (min-width: 900px)': {
      // TODO: need add breakpoint from config
      ...shorthands.flex(1.5),
    },
  },
  label: {
    display: 'block',
    fontSize: '1em',
    fontWeight: 500,
    '@media screen and (max-width: 900px)': {
      // TODO: need add breakpoint from config
      ...shorthands.flex(1.5),
    },

    '@media (max-width: 900px)': {
      fontSize: '0.75em',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    ...shorthands.gap('12px'),
    ...shorthands.flex(4),
  },
  day: {
    ...shorthands.flex(2),
    width: '60px',
    position: 'relative',

    '@media (max-width: 400px)': {
      ...shorthands.flex(2),
    },
  },
  monthContainer: {
    ...shorthands.flex(4),

    '@media (max-width: 400px)': {
      ...shorthands.flex(4),
    },
  },
  yearContainer: {
    ...shorthands.flex(3),
  },
  typography: {
    color: 'grey',
  },
});
