import { useEffect } from 'react';
import * as CookieSyncService from 'src/services/CookieSyncService';
import * as ImpressionService from 'src/services/ImpressionService';
import { isSuccess } from 'src/lib/remoteData';
import { clearUrlParams, getRegistrationUrlParams } from 'src/lib/url';
import { RegParamsStorageService } from 'src/services/AuthService';

import { useGlobalTasksListener } from 'src/providers/GlobalTasksProvider';
import { useFlowUpdater } from 'src/providers/RegFlowProvider';
import { useRecoilState } from 'recoil';
import {
  registrationParamsState,
  RegistrationParamsState,
} from 'src/recoil/registrationParams';
import {
  impressionResponseToRegistrationParams,
  syncResponseToRegistrationParams,
} from 'src/hooks/useExtraRegistrationParams/utils';

export interface RegistrationParamsProps {
  locale: string;
  features?: Features;
}

export const REG_IMP_TTL = 60 * 60 * 1000;

export const restoredRegistrationParams = {
  ...RegParamsStorageService.data,
  ...getRegistrationUrlParams(),
};

export function useExtraRegistrationParams({
  locale,
}: RegistrationParamsProps): {
  registrationParams: Partial<RegistrationParamsState>;
  clearRegistrationParams: () => void;
} {
  const [registrationParamsInState, setRegistrationParamsInState] =
    useRecoilState(registrationParamsState);

  const { createdAt, ...rest } = registrationParamsInState;
  const { clear: clearGlobalTasks } = useGlobalTasksListener();
  const { clear: clearFlowRemained } = useFlowUpdater();
  const clearRegistrationParams = useClearRegistrationParams();

  const load = async () => {
    const timeStamp = new Date().getTime();
    const isReRequest = createdAt && timeStamp - REG_IMP_TTL > createdAt;
    const isExpiredRegImpId = !createdAt || isReRequest;

    if (registrationParamsInState?.reg_imp_id && !isExpiredRegImpId) return;

    clearGlobalTasks();
    clearFlowRemained();

    let data: Partial<RegistrationParams> = {
      createdAt: timeStamp,
    };
    const syncResponse = await CookieSyncService.sync();

    if (isSuccess(syncResponse)) {
      data = {
        ...data,
        ...syncResponseToRegistrationParams(syncResponse.data),
      };
    }

    const impressionResponse = await ImpressionService.getRegistationImpression(
      {
        ...registrationParamsInState,
        oaid: data?.oaid,
        re_request: isReRequest ? 1 : 0,
      }
    );

    if (isSuccess(impressionResponse)) {
      data = {
        ...data,
        ...impressionResponseToRegistrationParams(impressionResponse.data),
      };
    }

    const meta = { ...rest, ...data };
    console.debug('update atom');
    setRegistrationParamsInState({ ...meta, language: locale });
    RegParamsStorageService.data = meta;
  };

  useEffect(() => {
    load();
  }, []);

  console.debug('registrationParamsInState', registrationParamsInState);
  return {
    registrationParams: registrationParamsInState,
    clearRegistrationParams,
  };
}

export const useClearRegistrationParams = () => {
  const [d, setRegistrationParamsInState] = useRecoilState(
    registrationParamsState
  );

  return () => {
    clearUrlParams();
    RegParamsStorageService.clear();
    setRegistrationParamsInState({});
  };
};
