import { makeStyles, shorthands } from '@griffel/react';

const zIndex = 1000;

export default makeStyles({
  button: {
    ...shorthands.borderRadius('2px'),
    ...shorthands.padding('4px', '32px'),
    height: '32px',
    width: '46px',
    fontWeight: 400,
    fontSize: '16px',
    zIndex: 100,
    '&:focus': {
      ...shorthands.outline('0'),
    },
    ...shorthands.border('0px'),
  },
  buttonWithRedesign: {
    ...shorthands.border('1px', 'solid', '#131526'),
    backgroundColor: 'inherit',
    color: '#FFFFFF',
  },
  buttonWithoutRedesign: {
    ...shorthands.border('1px', 'solid', '#131526'),
    backgroundColor: '#f9fafe',
    color: '#2E2E32',
    '&:hover': {
      backgroundColor: '#f9fafe',
    },
    border: 'none',
  },
  modalButton: {
    ...shorthands.padding('8px'),
    marginTop: '4px',
    color: '#2E2E32',
    display: 'flex',
    justifyContent: 'flex-start',
    '&:first-of-type': {
      marginTop: 0,
    },
  },
  modalButtonWithIsSelected: {
    backgroundColor: '#F6F8FA',
  },
  languageModalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: zIndex + 1,
    opacity: 1,
  },
  languageModalContentWrapper: {
    position: 'fixed',
    zIndex: zIndex + 2,
  },
  languageModalContentMain: {
    ...shorthands.flex('auto'),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: '100%',
  },
  languageModalContentMainWidthFull: {
    maxWidth: '100%',
  },
  languageModalContentMainWidthoutFull: {
    maxWidth: 'null',
  },
  languageModalContentButtons: {
    backgroundColor: '#FFF',
    ...shorthands.padding('8px'),
    ...shorthands.borderRadius('1rem'),
    width: '240px',

    maxHeight: '65vh',
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  languageModalContentImage: {
    ...shorthands.margin('0.5rem'),
    width: '20px',
    height: '15px',
  },
  arrow: {
    width: 0,
    height: 0,
    marginLeft: '12px',
    ...shorthands.borderLeft('5px', 'solid', 'transparent'),
    ...shorthands.borderRight('5px', 'solid', 'transparent'),
    ...shorthands.borderTop('7px', 'solid', 'rgb(75,75,75)'),
  },
});
