import { FunctionComponent } from 'react';
import MuiCheckbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';

import { ReactComponent as CheckIcon } from 'src/assets/icons/checkbox-checked.svg';

const CheckboxChecked = () => {
  return (
    <Box
      sx={(theme) => ({
        width: '20px',
          height: '20px',
        borderRadius: '4px',
          border: '1px solid white',
          background: theme.palette.primary.main,
        display: 'inline-flex',
        '& svg': {
          margin: 'auto',
        },
      })}
    >
      <CheckIcon />
    </Box>
  );
};

const CheckboxUnchecked: FunctionComponent<{}> = () => {
  return (
    <Box
      sx={(theme) => ({
        width: '20px',
        height: '20px',
        borderRadius: '4px',
          border: '1px solid white',
          backgroundColor: 'rgb(210,210,210)'
      })}
    />
  );
};

const Checkbox: FunctionComponent<{
  hasError: boolean;
  id: string;
  className?: string;
}> = ({ hasError, ...rest }) => {
  return (
    <>
        <MuiCheckbox
            {...rest}
            icon={<CheckboxUnchecked />}
            checkedIcon={<CheckboxChecked />}
            color={hasError ? 'error' : 'primary'}
        />
    </>
  );
};

export default Checkbox;
