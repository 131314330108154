import { FC, memo, useMemo } from 'react';
import { CircularProgressProps } from './types';
import useClasses from './get-styles';
import { mergeClasses } from '@griffel/react';
import './styles.css';

const CircularProgress: FC<CircularProgressProps> = ({
  size = 40,
  thickness = 3.6,
  className,
}) => {
  const styles = useClasses();
  const circleStyle = useMemo(
    () => ({
      strokeWidth: thickness,
    }),
    [thickness]
  );
  return (
    <svg
      className={mergeClasses(styles.root, 'rotate', className)}
      viewBox="22 22 44 44"
      style={{ width: size, height: size }}
    >
      <circle
        className={mergeClasses(styles.circle, 'dash')}
        cx="44"
        cy="44"
        r="20.2"
        fill="none"
        style={circleStyle}
      />
    </svg>
  );
};

export default memo(CircularProgress);
