import { makeStyles, shorthands } from '@griffel/react';
import Reviews from 'src/components/Landing/Reviews';

export default makeStyles({
  reviewsWrapper: {
    display: 'inline-flex',
    ...shorthands.gap('12px'),
  },
  review: {
    backgroundColor: '#FFFFFF',
    backgroundImage: 'url(/landing-quote.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: '30px',
    backgroundPositionY: '30px',
    ...shorthands.borderRadius('20px'),
    ...shorthands.padding('80px', '20px', '20px'),
    width: '320px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  reviewText: {
    fontSize: '18px',
  },
  reviewName: {
    fontSize: '18px',
    fontWeight: 700,
    marginTop: '24px',
  },
});
