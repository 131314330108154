import { useEffect, useRef } from 'react';

import TaboolaService from 'src/services/TaboolaService';
import { useProfileState } from 'src/providers/ProfileProvider';
import { useFeature } from 'src/providers/FeaturesProvider';
import { FEATURE } from 'src/constants/enums';

const useTaboolaInstaller = () => {
  const { profile } = useProfileState();
  const feature = useFeature(FEATURE.WHEEL_OF_FORTUNE);
  const isInjected = useRef(false);

  useEffect(() => {
    if (profile && !isInjected.current && feature) {
      TaboolaService.init();
      isInjected.current = true;
    }
  }, [feature, Boolean(profile)]);
};

export default useTaboolaInstaller;
