import { PropsWithChildren } from 'react';
import Box from 'src/rewardis-kit/components/box';
import { Dialog } from 'src/rewardis-kit/components/Modal';
import { ReactComponent as CloseIcon } from 'src/assets/icons/close.svg';
import useClasses from './get-styles';
import Typography from 'src/rewardis-kit/components/typography';
import { TypographyVariants } from 'src/rewardis-kit/components/typography/types';

interface DialogProps {
  noPadding?: boolean;
  withClose?: boolean;
  title?: string;
  fullWidth?: boolean;
  width?: string;
  isOpen?: boolean;
  handleClose?: () => void;
  CustomCloseIcon?: React.FC<React.SVGProps<SVGSVGElement>> | any;
  id?: string;
}

export default function GriffelBasedModal(
  props: DialogProps & PropsWithChildren
) {
  const styles = useClasses();
  const {
    title,
    children,
    isOpen,
    fullWidth,
    width = '320px',
    withClose = false,
    CustomCloseIcon,
    handleClose,
    id,
  } = props;

  return (
    <Dialog
      id={id}
      width={width}
      fullWidth={fullWidth}
      isOpen={isOpen}
      data-modal
    >
      {withClose ? (
        <Box onClick={handleClose} className={styles.closeIcon}>
          {CustomCloseIcon ? <CustomCloseIcon /> : <CloseIcon />}
        </Box>
      ) : null}
      {!title ? null : (
        <Box id="dialog-title" className={styles.titleBox}>
          <Typography variant={TypographyVariants.h6}>{title}</Typography>
        </Box>
      )}
      <Box className={styles.contentBox}>
        <Typography variant={TypographyVariants.body2}>{children}</Typography>
      </Box>
    </Dialog>
  );
}
