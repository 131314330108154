import { useIntl } from 'react-intl';
import Button from 'src/rewardis-kit/components/button';
import LogoImage from 'src/assets/google.svg';
import useClasses from 'src/components/buttons/get-styles';

interface GoogleButtonProps {
  onClick: () => void;
}

export default function GoogleButtonMobile(props: GoogleButtonProps) {
  const intl = useIntl();
  const styles = useClasses();

  return (
    <Button onClick={props.onClick} className={styles.googleButtonMobile}>
      <img
        src={LogoImage}
        width={21}
        height={22}
        style={{ marginRight: '5px' }}
      />
      {intl.formatMessage({
        id: 'signInWithGoogle',
        defaultMessage: 'Sign in with Google',
      })}
    </Button>
  );
}
