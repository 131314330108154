import React, { FC, useCallback, useMemo } from 'react';
import Input from 'src/rewardis-kit/components/input';
import useClasses from './get-styles';
import {
  CoreSelectorProps,
  Option,
  SingleSelector,
} from 'src/rewardis-kit/components/core-selector';

interface DataSelector<T> {
  options: Option<T>[];
  onChange: (options: Option<T>[]) => void;
  selectedOptions: Option<T>[];
  hasError: boolean;
}

const RenderOptionsContainer: CoreSelectorProps<number>['RenderOptionsContainer'] =
  React.forwardRef(({ children }, ref) => {
    const styles = useClasses();

    return (
      <div
        className={styles.container}
        ref={ref as React.LegacyRef<HTMLDivElement>}
      >
        {children}
      </div>
    );
  });

const RenderSelected: FC<{
  options: Option<number>[];
  setIsOpen: (value: boolean) => void;
  hasError: boolean;
}> = ({ options, setIsOpen, hasError }) => {
  const styles = useClasses();

  const onOpen = useCallback(() => setIsOpen(true), [setIsOpen]);

  return (
    <Input
      readOnly
      className={styles.selected}
      value={options?.[0]?.label}
      onClick={onOpen}
      error={hasError}
    />
  );
};

const RenderOption: CoreSelectorProps<number>['RenderOption'] = ({
  onClick,
  label,
  id,
}) => {
  const styles = useClasses();

  return (
    <div className={styles.option} key={id} onClick={onClick}>
      {label}
    </div>
  );
};

export const DateSelector = ({ hasError, ...rest }: DataSelector<number>) => {
  const styles = useClasses();

  const WrappedRenderSelected: FC<{
    options: Option<number>[];
    setIsOpen: (value: boolean) => void;
  }> = useMemo(
    () => (props) => <RenderSelected {...props} hasError={hasError} />,
    [hasError]
  );

  return (
    <div className={styles.root}>
      <SingleSelector<number>
        {...rest}
        RenderOption={RenderOption}
        RenderSelected={WrappedRenderSelected}
        RenderOptionsContainer={RenderOptionsContainer}
      />
    </div>
  );
};
