import React, {
  FC,
  memo,
  MouseEventHandler,
  useCallback,
  useState,
} from 'react';
import useClasses from './get-styles';
import { mergeClasses } from '@griffel/react';
import { DialogProps } from 'src/rewardis-kit/components/Modal/components/dialog/types';

const stopProp: MouseEventHandler = (e) => e.stopPropagation();

const DialogComponent: FC<DialogProps> = ({
  className,
  id,
  onClose,
  isOpen,
  children,
  width,
  styles,
  backGroundStyles,
  backGroundClassName,
  backGroundId,
}) => {
  const classes = useClasses();
  const [openState, setOpenState] = useState(isOpen);

  const handleBackClick = useCallback(() => {
    setOpenState(false);
    onClose?.();
  }, [onClose]);

  if (typeof isOpen === 'boolean' ? !isOpen : !openState) return null;

  return (
    <div
      className={mergeClasses(classes.backGround, backGroundClassName)}
      id={backGroundId}
      onClick={handleBackClick}
      style={backGroundStyles}
    >
      <div
        className={mergeClasses(classes.dialog, className)}
        id={id}
        style={{ ...styles, maxWidth: width || '50%' }}
        onClick={stopProp}
      >
        {children}
      </div>
    </div>
  );
};

export default memo(DialogComponent);
