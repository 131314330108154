import { makeStyles, shorthands } from '@griffel/react';

export default makeStyles({
  dialog: {
    width: 'calc(100% - 32px)',
    maxHeight: 'calc(100% - 32px)',
    boxSizing: 'border-box',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    overflowY: 'scroll',
    zIndex: 1000,
    ...shorthands.borderRadius('16px'),
  },
  backGround: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
});
