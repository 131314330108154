import { useEffect, useRef } from 'react';
import { getPopupZones } from 'src/utils/popup';
import { setupPopupTag } from 'src/lib/extraScripts';
import { addPopunderClickReg } from 'src/lib/popunder';
import { useGlobalTasksListener } from 'src/providers/GlobalTasksProvider';
import { useFeature } from 'src/providers/FeaturesProvider';
import { FEATURE } from 'src/constants/enums';
import useLastClick from 'src/hooks/useLastClick';
import { useProfileState } from 'src/providers/ProfileProvider';
import { POP_TAG_IGNORE_SUB_PARAM } from 'src/constants/app';

const usePopup = (feature: FEATURE.POPUP | FEATURE.FORTUNE_POPUP) => {
  const { task } = useGlobalTasksListener();
  const featurePopup = useFeature(feature);
  const { getLast, clear } = useLastClick();
  const isPopupInjected = useRef<boolean>();
  const { profile } = useProfileState();

  useEffect(() => {
    if (
      profile &&
      !task &&
      profile.birth_date &&
      profile.sub_id_1 &&
      profile.sub_id_1 !== POP_TAG_IGNORE_SUB_PARAM &&
      !isPopupInjected.current &&
      featurePopup
    ) {
      const zones = getPopupZones(featurePopup, profile);
      const randomZoneId = zones[Math.floor(Math.random() * zones.length)];

      setupPopupTag(profile, randomZoneId, featurePopup.link);
      addPopunderClickReg(randomZoneId, getLast, clear);

      isPopupInjected.current = true;
    }
  }, [profile, task, featurePopup]);
};

export default usePopup;
