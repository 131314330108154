import * as hooks from 'src/hooks/extraScripts';
import { FEATURE } from 'src/constants/enums';

export const isPushGranted = () => {
  return 'Notification' in window && Notification.permission === 'granted';
};

export default function ExtraScriptsProvider(): JSX.Element {
  hooks.usePushSubscriptionNotAuth();
  hooks.usePopup(FEATURE.POPUP);
  hooks.usePopup(FEATURE.FORTUNE_POPUP);
  hooks.useSAM();
  hooks.usePushSubscription();
  hooks.useFortunePopunder();
  hooks.useTaboolaInstaller();
  hooks.usePixelHTML();

  return <></>;
}
