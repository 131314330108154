import {
  createContext,
  PropsWithChildren,
  useState,
  useContext,
  useReducer,
  useEffect,
} from 'react';

import {
  useGoogleLogin,
  googleLogout,
  TokenResponse,
  GoogleOAuthProvider,
} from '@react-oauth/google';
import { smartlookTrackEvent } from 'src/services/GoogleTagManagerService';
import { SMARTLOOK_CUSTOM_EVENT } from 'src/constants/enums';
import * as ImpressionService from 'src/services/ImpressionService';
import { useRecoilValue } from 'recoil';
import { registrationParamsGetState } from 'src/recoil/registrationParams';
import {
  getGoogleAccountInfo,
  getGooglePersonalData,
} from 'src/services/AuthService';

const clientId =
  '131248382018-e0pfb6btstu9rhvqm4mc0sleugiacrhu.apps.googleusercontent.com';

type PartialProfile = {
  first_name: string;
  last_name: string;
  email: string;
  birth_date?: string;
  gender?: Gender;
  avatar?: string;
};

type State = PartialProfile | null;

type Dispatch = (action: Action) => void;
type Action =
  | { type: 'resetProfile' }
  | { type: 'setProfile'; payload: { profile: PartialProfile } };

const GoogleAuthStateContext = createContext<State | undefined>(undefined);
const GoogleAuthDispatchContext = createContext<Dispatch | undefined>(
  undefined
);

function authReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'resetProfile': {
      googleLogout();
      return null;
    }
    case 'setProfile': {
      return action.payload.profile;
    }
    default:
      throw new Error(`Unhandled action`);
  }
}

export function useGoogleAuthState() {
  const context = useContext(GoogleAuthStateContext);

  if (context === undefined) {
    throw new Error('useAuthState must be used within a GoogleAuthProvider');
  }

  return {
    profile: context,
  };
}

export function useGoogleAuthDispatch(withExtraScope: boolean = false) {
  const dispatch = useContext(GoogleAuthDispatchContext);

  if (dispatch === undefined) {
    throw new Error('useAuthDispatch must be used within a GoogleAuthProvider');
  }

  const login = useGoogleAuthRequest((profile) => {
    dispatch({
      type: 'setProfile',
      payload: { profile },
    });
  }, withExtraScope);

  return {
    login: () => {
      smartlookTrackEvent(SMARTLOOK_CUSTOM_EVENT.GOOGLE_AUTH);
      login();
    },
    logout: () => {
      googleLogout();
      dispatch({ type: 'resetProfile' });
    },
  };
}

const getGoogleExtraScope = async (accessToken: string) => {
  const data: {
    birth_date?: string;
    gender?: Gender;
  } = {};

  const fields = 'birthdays,genders';
  const peopleData = await getGooglePersonalData(accessToken, fields);
  if (!peopleData) {
    return data;
  }
  const rawGender = peopleData?.genders?.find((gender: any) => gender.value);
  switch (rawGender?.value) {
    case 'male':
      data.gender = 1;
      break;
    case 'female':
      data.gender = 2;
      break;
  }
  const rawBirthday = peopleData?.birthdays?.find(
    (birthday: any) =>
      birthday.date.year && birthday.date.month && birthday.date.day
  );
  if (rawBirthday) {
    const { year, month, day } = rawBirthday.date;

    data.birth_date = `${year}-${month}-${day}`;
  }

  return data;
};

function useGoogleAuthRequest(
  onProfile: (profile: PartialProfile) => void,
  withExtraScope: boolean = false
) {
  const [authResponse, setAuthResponse] = useState<TokenResponse>();
  const regParams = useRecoilValue(registrationParamsGetState);

  const login = useGoogleLogin({
    scope: withExtraScope
      ? 'https://www.googleapis.com/auth/user.gender.read https://www.googleapis.com/auth/user.birthday.read'
      : undefined,
    onSuccess: (tokenResponse) => setAuthResponse(tokenResponse),
  });

  useEffect(() => {
    (async () => {
      if (!authResponse || !regParams || !regParams?.reg_imp_id) {
        return;
      }
      const userInfoData = await getGoogleAccountInfo(
        authResponse.access_token
      );
      if (!userInfoData) {
        return;
      }

      const profileFields: PartialProfile = {
        email: userInfoData.email,
        first_name: userInfoData.given_name,
        last_name: userInfoData.family_name,
        avatar: userInfoData.picture,
        ...(withExtraScope
          ? await getGoogleExtraScope(authResponse.access_token)
          : {}),
      };

      onProfile(profileFields);
      await ImpressionService.saveRegImpressionId(
        authResponse.access_token,
        regParams.reg_imp_id
      );
    })();
  }, [authResponse, regParams]);

  return login;
}

export default function ProfileProvider({ children }: PropsWithChildren) {
  const [state, dispatch] = useReducer(authReducer, null);

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <GoogleAuthStateContext.Provider value={state}>
        <GoogleAuthDispatchContext.Provider value={dispatch}>
          {children}
        </GoogleAuthDispatchContext.Provider>
      </GoogleAuthStateContext.Provider>
    </GoogleOAuthProvider>
  );
}
