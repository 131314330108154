import { PROJECT_ALIAS, PROJECT_ALIASES } from 'src/constants/app';
import {
  biwardDefaultFields,
  defaultFields,
  modalAgreementFields,
} from 'src/components/forms/RegistationForm/contstants';
import { FieldName } from 'src/components/forms/RegistationForm/typings';
import { useFeature } from 'src/providers/FeaturesProvider';
import { FEATURE } from 'src/constants/enums';
import { restoredRegistrationParams } from 'src/hooks/useExtraRegistrationParams';

const withRecapcthaField = (
  fields: FieldName[],
  ab_experiment_id?: number
): FieldName[] => {
  const feature = useFeature(FEATURE.RECAPTCHA);

  if (feature?.zones.includes(Number(restoredRegistrationParams.sub_id1))) {
    return [...fields, 'custom_captcha'];
  }

  if (
    ab_experiment_id === 66 &&
    Number(restoredRegistrationParams.sub_id1) === 6431942
  ) {
    return [...fields, 'custom_captcha'];
  }

  return fields;
};

export const createFields = (
  fieldsMap: FieldName[],
  ab_experiment_id?: number
) =>
  PROJECT_ALIAS === PROJECT_ALIASES.REWARDIS
    ? withRecapcthaField(fieldsMap, ab_experiment_id)
    : biwardDefaultFields;
