import React, { memo, ElementType, ReactElement, useMemo } from 'react';
import { BoxProps } from './types';
import useClasses from './get-styles';
import { mergeClasses } from '@griffel/react';

const BoxComponent = <T extends ElementType = 'div'>({
  component,
  children,
  className,
  ...rest
}: BoxProps<T>): ReactElement | null => {
  const Component = component || 'div';
  const styles = useClasses();
  const styledComponents: string | undefined = useMemo(
    () =>
      ['footer', 'header', 'section'].includes(component as string)
        ? styles.container
        : '',
    [styles, component]
  );

  return (
    <Component className={mergeClasses(styledComponents, className)} {...rest}>
      {children}
    </Component>
  );
};

export default memo(BoxComponent);
