import { Outlet } from 'react-router-dom';
import WebsocketProvider from 'src/providers/WebsocketProvider';
import TaskIFrameProvider from 'src/providers/TaskIFrameProvider';
import ApplixirProvider from 'src/providers/ApplixirProvider';
import ErrorBoundary from 'src/components/ErrorBoundary';
import WebsocketUserMessages from 'src/providers/WebsocketUserMessages';
import GoogleAuthProvider from 'src/providers/GoogleAuthProvider';
import ExtraScriptsProvider from 'src/providers/ExtraScriptsProvider';
import ObserveLocationProvider from 'src/providers/ObserveLocationProvider';
import EmailVerificationProvider from 'src/providers/EmailVerificationProvider';
import FeaturesProvider from 'src/providers/FeaturesProvider';
import PostRegProvider from 'src/providers/PostRegProvider';
import UpdateMainStateMessagesProvider from 'src/providers/UpdateMainStateProvider';
import usePopupV2 from 'src/hooks/usePopupV2';
import { useBrowserChanger } from 'src/hooks/useBrowserChanger';

import './style.css';
import { GlobalTaskProvider } from 'src/providers/GlobalTasksProvider';
import { useEffect } from 'react';
import {
  addGTMFrame,
  init as initGTM,
  registerGTMUser,
} from 'src/services/GoogleTagManagerService';
import { useProfileState } from 'src/providers/ProfileProvider';

const AppDynamic = () => {
  const { profile } = useProfileState();
  const isAuth = !!profile;

  useBrowserChanger();
  usePopupV2();

  useEffect(() => {
    addGTMFrame();
    initGTM();

    if (isAuth && profile?.id) {
      registerGTMUser(profile?.id);
    }
  }, [isAuth]);
  return (
    <WebsocketProvider>
      <FeaturesProvider />
      <TaskIFrameProvider>
        <ApplixirProvider>
          <ErrorBoundary>
            <GoogleAuthProvider>
              <Outlet />
            </GoogleAuthProvider>
          </ErrorBoundary>
        </ApplixirProvider>
      </TaskIFrameProvider>
      <ExtraScriptsProvider />
      <WebsocketUserMessages />
      <EmailVerificationProvider />
      <PostRegProvider />
      <ObserveLocationProvider />
      <UpdateMainStateMessagesProvider />
      <GlobalTaskProvider />
    </WebsocketProvider>
  );
};

export default AppDynamic;
