import * as yup from 'yup';
import {
  useMemo,
  memo,
  useCallback,
  useEffect,
  FunctionComponent,
  PropsWithChildren,
  FormEvent,
  useState,
} from 'react';
import { FormikProvider, FormikHelpers, useFormik } from 'formik';
import { IntlShape, useIntl } from 'react-intl';
import Box from 'src/rewardis-kit/components/box';
import Button from 'src/rewardis-kit/components/button';
import CircularProgress from 'src/rewardis-kit/components/circular-progress';
import * as gtm from 'src/lib/gtm';
import * as BirthdayFields from './fields/BirthdayFields';
import * as FirstNameField from './fields/FirstNameField';
import * as LastNameField from './fields/LastNameField';
import * as EmailField from './fields/EmailField';
import * as HiddenField from './fields/HiddenField';
import * as GenderField from './fields/GenderField';
import * as PhoneField from './fields/PhoneField';
import * as PasswordField from './fields/PasswordField';
import * as RecaptchaV2Field from './fields/RecaptchaV2Field';
import * as RecaptchaV3Field from './fields/RecaptchaV3Field';
import * as PrivacyAgreementField from './fields/PrivacyAgreement/PrivacyAgreementField';
import * as ZipFields from './fields/ZipFields';
import * as CustomCaptchaField from './fields/CustomCaptchField';
import * as PrivacyAgreementModalField from './fields/PrivacyAgreement/ModalField/PrivacyAgreementModalField';
import { FieldFactory, FieldName } from './typings';
import { SignupBody, RegFormStorageService } from 'src/services/AuthService';
import useRecaptchaAwait from 'src/hooks/useRecaptchAwait';
import {
  useGoogleAuthDispatch,
  useGoogleAuthState,
} from 'src/providers/GoogleAuthProvider';
import GoogleButtonMobile from 'src/components/buttons/GoogleButtonMobile';
import GoogleButtonStandard from 'src/components/buttons/GoogleButtonStandard';
import { ErrorContainer, createErrorContainer } from 'src/lib/errors';
import { useTimestampFirstInteraction } from './useTimestampFirstInteraction';
import { SMARTLOOK_CUSTOM_EVENT } from 'src/constants/enums';
import { smartlookTrackEvent } from 'src/services/GoogleTagManagerService';
import { GOOGLE_BUTTON_POSITION, LANDING_ID } from 'src/constants/landings';
import { modalAgreementFields } from 'src/components/forms/RegistationForm/contstants';
import useClasses from './get-styles';
import { useRecaptchaMutation } from 'src/recoil/recaptcha';
import { useReCaptchaContext } from 'src/providers/ReCaptchaProvider';

type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
};

const FieldFactories: Record<FieldName, FieldFactory> = {
  first_name: FirstNameField,
  last_name: LastNameField,
  email: EmailField,
  password: PasswordField,
  confirm_password: PasswordField,
  phone: PhoneField,
  gender: GenderField,
  postcode: ZipFields,
  birth_date: BirthdayFields,
  captchaV2: RecaptchaV2Field,
  captchaV3: RecaptchaV3Field,
  privacy_agreement: PrivacyAgreementField,
  custom_captcha: CustomCaptchaField,
  privacy_agreement_modal: PrivacyAgreementModalField,
  city: HiddenField,
  terms: HiddenField,
  chosen_prize: HiddenField,
};

interface ValidationSchemaProps {
  intl: IntlShape;
}

const createFormInitialValues = (fieldNames: FieldName[]) => {
  const values: PartialRecord<FieldName, any> = {};

  fieldNames.forEach((fieldName) => {
    if (fieldName in FieldFactories) {
      const name = (FieldFactories[fieldName].name ?? fieldName) as FieldName;

      values[name] = FieldFactories[fieldName].initialValue;
    }
  });

  return values;
};

const createValidationSchema = (
  fieldNames: FieldName[],
  props: ValidationSchemaProps
) => {
  const validationSchema: PartialRecord<FieldName, any> = {};

  fieldNames.forEach((fieldName) => {
    if (fieldName in FieldFactories) {
      const name = (FieldFactories[fieldName].name ?? fieldName) as FieldName;

      validationSchema[name] =
        FieldFactories[fieldName].createValidationSchema(props);
    }
  });

  return yup.object(validationSchema);
};

interface RegistrationFormProps {
  abExperimentId?: number;
  designId: number;
  fields: FieldName[];
  blacklist?: string[];
  extInitialValues?: PartialRecord<FieldName, string | boolean | number>;
  recaptchaVersion?: RecaptchaVersion;
  handleMarketingClick: () => void;
  handleTermsClick: () => void;
  handlePrivacyClick: () => void;
  handleErrorsChange: (errors: any, errorContainer?: ErrorContainer) => void;
  handleSubmit: (
    values: SignupBody & { captchaV2?: string; captchaV3?: string },
    formikHelpers: FormikHelpers<any>
  ) => Promise<void>;
  onFirstInteraction?: (timestamp: number) => void;
  mobileGoogleButtonPosition: GOOGLE_BUTTON_POSITION;
}

const FieldRow: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const styles = useClasses();
  return <Box className={styles.fieldRow}>{children}</Box>;
};

type FormValues = PartialRecord<FieldName, any>;

const Separator = memo(() => {
  const intl = useIntl();
  const styles = useClasses();

  return (
    <Box className={styles.separator}>
      {intl.formatMessage({ id: 'sign_up.or', defaultMessage: 'Or' })}
    </Box>
  );
});

function RegistrationForm({
  abExperimentId,
  fields,
  extInitialValues,
  blacklist = [],
  recaptchaVersion,
  handleSubmit,
  handleErrorsChange,
  handleTermsClick,
  handlePrivacyClick,
  handleMarketingClick,
  onFirstInteraction,
  mobileGoogleButtonPosition,
  designId,
}: RegistrationFormProps) {
  const intl = useIntl();
  const { profile } = useGoogleAuthState();
  const validationProps = { intl, blacklist, recaptchaVersion };
  const needV3 = fields.includes('captchaV3') && recaptchaVersion === 3;
  const { readyToInit } = useReCaptchaContext();
  const { login } = useGoogleAuthDispatch(
    69 === abExperimentId || LANDING_ID.HIDE_GOOGLE_AUTH_FULL_DATA === designId
  );
  const styles = useClasses();

  const initialValues = useMemo(
    () =>
      RegFormStorageService.data ?? {
        ...createFormInitialValues(fields),
        ...extInitialValues,
      },
    [fields]
  );

  const validationSchema = useMemo(
    () => createValidationSchema(fields, validationProps),
    [fields, validationProps]
  );

  const formik = useFormik<FormValues>({
    initialValues,
    validationSchema,
    validateOnMount: false,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values, helpers) =>
      handleSubmit(
        {
          ...values,
          age: values?.custom_captcha?.result,
        },
        helpers
      ),
  });

  useEffect(() => {
    if (profile) {
      const { last_name, ...rest } = profile;
      formik.setValues({
        ...formik.values,
        ...rest,
        last_name: last_name?.length > 0 ? last_name : formik.values?.last_name,
      });

      setTimeout(() => {
        [
          LANDING_ID.HIDE_GOOGLE_AUTH_FULL_DATA,
          LANDING_ID.HIDE_GOOGLE_AUTH_PART_DATA,
          LANDING_ID.SHORT_FORM,
        ].includes(designId) &&
          formik.setTouched(
            modalAgreementFields.reduce(
              (acc, value) => ({ ...acc, [value]: true }),
              {}
            )
          );
        if (designId === LANDING_ID.SHORT_FORM) {
          formik.setSubmitting(true);
          next();
        }
      }, 10);
    }
  }, [profile]);

  useEffect(() => {
    if (formik.values.gender === 1) {
      gtm.set('gender', 'male');
    }
    if (formik.values.gender === 2) {
      gtm.set('gender', 'female');
    }
  }, [formik.values]);

  useTimestampFirstInteraction(
    initialValues,
    formik.values,
    onFirstInteraction || (() => {})
  );

  const submitForm = useCallback(async () => {
    if (Object.keys(formik.errors).length) {
      let errorContainer;
      try {
        await validationSchema.validate(formik.values, {
          abortEarly: false,
        });
      } catch (err) {
        errorContainer = createErrorContainer(err as yup.ValidationError);
      }
      const errors = Object.entries(formik.errors)
        .filter(([key, _]) => key !== 'captchaV3')
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

      handleErrorsChange(errors, errorContainer);
    }
    formik.handleSubmit();
  }, [
    formik.errors,
    formik.handleSubmit,
    formik.values,
    validationSchema,
    handleErrorsChange,
  ]);

  const submitManually = () => {
    formik.setSubmitting(true);
    handleSubmit(
      {
        ...formik.values,
        age: formik.values?.custom_captcha?.result,
      },
      {
        setSubmitting: formik.setSubmitting,
      } as any
    );
  };

  const next = useRecaptchaAwait(needV3, () => {
    if (RegFormStorageService.hasData) {
      submitManually();
      RegFormStorageService.clear();
    } else {
      submitForm();
    }
  });

  const handleSubmitForm = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      if (needV3 && !readyToInit) {
        submitForm();
        return;
      }
      smartlookTrackEvent(SMARTLOOK_CUSTOM_EVENT.SIGNUP_BUTTON_CLICK);
      formik.setSubmitting(true);
      next();
    },
    [submitForm]
  );

  useEffect(() => {
    if (RegFormStorageService.hasData) {
      next();
    }
  }, []);

  return (
    <FormikProvider value={formik}>
      <form onReset={formik.handleReset} onSubmit={handleSubmitForm}>
        <HiddenField.Component
          position="zIndex"
          name="chosen_prize"
          type="text"
        />
        <HiddenField.Component position="margin" name="terms" type="checkbox" />
        {mobileGoogleButtonPosition === GOOGLE_BUTTON_POSITION.TOP && (
          <Box className={styles.mobileGoogleButton}>
            <GoogleButtonStandard onClick={login} isMobile />
          </Box>
        )}
        {(fields.includes('first_name') || fields.includes('last_name')) && (
          <Box className={styles.field}>
            {fields.includes('first_name') ? (
              <FirstNameField.Component name="first_name" autoFocus />
            ) : null}
            {fields.includes('last_name') ? (
              <LastNameField.Component name="last_name" />
            ) : null}
          </Box>
        )}
        {fields.includes('email') ? (
          <FieldRow>
            <EmailField.Component name="email" />
          </FieldRow>
        ) : null}
        {mobileGoogleButtonPosition === GOOGLE_BUTTON_POSITION.CENTER && (
          <GoogleButtonMobile onClick={login} />
        )}
        {fields.includes('password') ? (
          <FieldRow>
            <PasswordField.Component
              name="password"
              defaultMessage="Password"
            />
          </FieldRow>
        ) : null}
        {fields.includes('confirm_password') ? (
          <FieldRow>
            <PasswordField.Component
              name="confirm_password"
              defaultMessage="Confirm password"
            />
          </FieldRow>
        ) : null}
        {fields.includes('birth_date') ? (
          <FieldRow>
            <BirthdayFields.Component name="birth_date" touchRequired />
          </FieldRow>
        ) : null}
        {fields.includes('gender') ? (
          <FieldRow>
            <GenderField.Component name="gender" />
          </FieldRow>
        ) : null}
        {fields.includes('phone') ? (
          <Box className={styles.phonePostCode}>
            <PhoneField.Component name="phone" />
          </Box>
        ) : null}
        {fields.includes('postcode') ? (
          <Box className={styles.phonePostCode}>
            <ZipFields.Component name="postcode" />
          </Box>
        ) : null}
        {mobileGoogleButtonPosition === GOOGLE_BUTTON_POSITION.BOTTOM && (
          <GoogleButtonStandard onClick={login} isMobile />
        )}
        {fields.includes('captchaV2') && recaptchaVersion === 2 ? (
          <RecaptchaV2Field.Component
            isSubmitting={formik.isSubmitting}
            name="captchaV2"
          />
        ) : null}
        {needV3 ? (
          <RecaptchaV3Field.Component
            name="captchaV3"
            action="signin"
            callback={next}
          />
        ) : null}
        {fields.includes('custom_captcha') && recaptchaVersion === 3 ? (
          <CustomCaptchaField.Component name="custom_captcha" />
        ) : null}
        {fields.includes('privacy_agreement') && (
          <PrivacyAgreementField.Component
            name="privacy_agreement"
            onTermsLinkClick={handleTermsClick}
            onPrivacyLinkClick={handlePrivacyClick}
            onMarketingLinkClick={handleMarketingClick}
          />
        )}
        {fields?.includes('privacy_agreement_modal') && (
          <PrivacyAgreementModalField.Component
            name="privacy_agreement"
            onMarketingLinkClick={handleMarketingClick}
            onPrivacyLinkClick={handlePrivacyClick}
            onTermsLinkClick={handleTermsClick}
          />
        )}
        <Box className={styles.buttonWrapper}>
          <Button
            size="lg"
            disabled={formik.isSubmitting}
            color="primary"
            variant="contained"
            type="submit"
            id="signup_button"
            onClick={handleSubmitForm}
            data-reg-popup
          >
            {formik.isSubmitting ? (
              <CircularProgress size={26} />
            ) : (
              intl.formatMessage({
                id: 'createAccount',
                defaultMessage: 'Create an account',
              })
            )}
          </Button>
          {mobileGoogleButtonPosition !== GOOGLE_BUTTON_POSITION.NONE && (
            <GoogleButtonStandard onClick={login} />
          )}
        </Box>
      </form>
    </FormikProvider>
  );
}

export default memo(RegistrationForm);
