import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
  memo,
} from 'react';

import { CoreSelectorProps, Option } from './types';
import { Options } from './Options';

import useClickOutside from '../../hooks/useClickOutside';
import { useClasses } from './get-styles';
import { mergeClasses } from '@griffel/react';

export const CoreSelectorComponent = <T,>({
  options,
  selectedOptions: initialSelectedOptions,
  RenderOption,
  RenderSelected,
  RenderOptionsContainer,
  handleOptionClick,
  isOpened,
}: CoreSelectorProps<T>) => {
  const [isOpen, setIsOpen] = useState(isOpened);
  const [selectedOptions, setSelectedOptions] = useState(
    initialSelectedOptions
  );
  const optionsContainerRef = useRef(null);
  const styles = useClasses();
  useEffect(
    () => setSelectedOptions(initialSelectedOptions),
    [initialSelectedOptions]
  );

  const handleOptionClickInternal = useCallback(
    (option: Option<T>) => {
      setIsOpen(false);
      handleOptionClick!(option);
      setSelectedOptions([option]);
    },
    [handleOptionClick]
  );

  const handleCloseContainer = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  useClickOutside(optionsContainerRef, handleCloseContainer);

  const selectedLabel = useMemo(
    () =>
      RenderSelected ? (
        <RenderSelected options={selectedOptions} setIsOpen={setIsOpen} />
      ) : (
        selectedOptions.map((o) => o.label).join(', ')
      ),
    [RenderSelected, selectedOptions, setIsOpen]
  );

  const RenderedSelectedContainer = useMemo(
    () =>
      RenderSelected ? (
        <RenderSelected options={selectedOptions} setIsOpen={setIsOpen} />
      ) : (
        <div ref={optionsContainerRef} onClick={handleCloseContainer}>
          {selectedLabel}
        </div>
      ),
    [RenderSelected, selectedLabel, selectedOptions, setIsOpen]
  );

  const RenderedOptionsContainerElement = useMemo(
    () =>
      RenderOptionsContainer ? (
        <RenderOptionsContainer ref={optionsContainerRef}>
          <Options<T>
            options={options}
            handleOptionClick={handleOptionClickInternal}
            selectedOptions={selectedOptions}
            RenderOption={RenderOption}
          />
        </RenderOptionsContainer>
      ) : (
        <div ref={optionsContainerRef}>
          <Options<T>
            options={options}
            handleOptionClick={handleOptionClickInternal}
            selectedOptions={selectedOptions}
            RenderOption={RenderOption}
          />
        </div>
      ),
    [
      RenderOption,
      RenderOptionsContainer,
      options,
      handleOptionClickInternal,
      selectedOptions,
    ]
  );

  return (
    <>
      {RenderedSelectedContainer}
      <div className={mergeClasses(styles.dropdown, isOpen && styles.withOpen)}>
        {RenderedOptionsContainerElement}
      </div>
    </>
  );
};

export const CoreSelector = memo(
  CoreSelectorComponent
) as unknown as typeof CoreSelectorComponent;
