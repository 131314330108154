import { makeStyles, shorthands } from '@griffel/react';

const useClasses = makeStyles({
  root: {
    color: 'var(--link-default-color)',

    ':hover': {
      color: 'var(--link-hover-color)',
    },

    ':visited': {
      color: 'var(--link-visited-color)',
    },
  },
  withUnderLine: {
    textDecoration: 'underline',
  },
});

export default useClasses;
