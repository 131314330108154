import * as Sentry from '@sentry/browser';
import { PropsWithChildren, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { ErrorBoundary } from 'react-error-boundary';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface ErrorHandlerProps {
  error: Error;
}

function ErrorFallback({ error }: ErrorHandlerProps) {
  const intl = useIntl();

  useEffect(() => {
    if (error) {
      Sentry.captureException(error);
    }
  }, [error]);

  return (
    <Box sx={{ margin: '2em' }}>
      <Alert severity="error">
        <Typography variant="h5">
          {intl.formatMessage({
            id: 'error.occurred',
            defaultMessage: 'An error occurred',
          })}
          :
        </Typography>
        <pre>{error.message || error.toString()}</pre>
      </Alert>
    </Box>
  );
}

export default function ErrorBoundaryWrapper({ children }: PropsWithChildren) {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
  );
}
