import * as yup from 'yup';
import { memo, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { FieldHookConfig, useField } from 'formik';
import Box from 'src/rewardis-kit/components/box';

import { daysInMonth } from 'src/lib/date';
import { ValidationSchemaProps } from '../../typings';
import {
  createDayMenuItems,
  createMonthMenuItems,
  createYearsMenuItems,
  CURRENT_DAY,
  CURRENT_MONTH,
  parseBirthDate,
  prepareBirthDate,
  YEAR_TO,
} from 'src/components/forms/RegistationForm/fields/BirthdayFields/utils';
import {
  ageLimitMessage,
  isDateWithinAgeLimits,
  requiredMessage,
} from 'src/components/forms/RegistationForm/fields/BirthdayFields/validation';
import useClasses from './get-styles';
import { DateSelector } from 'src/components/forms/RegistationForm/fields/BirthdayFields/Selector';

export const initialValue = '';

export const createValidationSchema = ({ intl }: ValidationSchemaProps) =>
  yup
    .date()
    .required(requiredMessage(intl))
    .test('ageLimit', ageLimitMessage(intl), isDateWithinAgeLimits);

const BirthdayFields = (
  props: { touchRequired?: boolean } & FieldHookConfig<string>
) => {
  const styles = useClasses();
  const [field, meta, helpers] = useField(props);
  const { year, month, day } = parseBirthDate(field.value);
  const [fieldsTouched, setFieldsTouched] = useState<boolean>(
    Boolean(!props.touchRequired)
  );
  const [birthdateDay, setBirthdateDay] = useState<number>(day);
  const [birthdateMonth, setBirthdateMonth] = useState<number>(month);
  const [birthdateYear, setBirthdateYear] = useState<number>(year);

  const intl = useIntl();

  useEffect(() => {
    setBirthdateDay(day);
    setBirthdateMonth(month);
    setBirthdateYear(year);
  }, [field.value]);

  useEffect(() => {
    if (fieldsTouched) {
      if (birthdateDay && birthdateMonth && birthdateYear) {
        helpers.setValue(
          prepareBirthDate(birthdateDay, birthdateMonth, birthdateYear)
        );
      } else {
        helpers.setValue('');
      }
    }
  }, [fieldsTouched, birthdateDay, birthdateMonth, birthdateYear]);

  const onFieldTouch = () => {
    if (!fieldsTouched) {
      setFieldsTouched(true);
    }
  };

  const hasError = Boolean(meta.touched && meta.error);

  return (
    <Box className={styles.container}>
      <Box className={styles.labelContainer}>
        <Box className={styles.label} id={'birthDayLabel'}>
          {intl.formatMessage({
            id: 'form.birth_date',
            defaultMessage: 'Birth date',
          })}
        </Box>
      </Box>

      <Box className={styles.wrapper}>
        <Box key={1} onClick={onFieldTouch} className={styles.day}>
          <DateSelector
            hasError={hasError}
            onChange={(selected) => {
              setBirthdateDay(Number(selected[0].value));
            }}
            selectedOptions={[
              {
                value: birthdateDay,
                label: birthdateDay.toString(),
                id: birthdateDay.toString(),
              },
            ]}
            options={createDayMenuItems(birthdateYear, birthdateMonth)}
          />
        </Box>

        <Box onClick={onFieldTouch} className={styles.monthContainer}>
          <DateSelector
            hasError={hasError}
            onChange={(selected) => {
              const month = selected[0].value;
              const days = daysInMonth(birthdateYear, month);

              const newDays =
                year === YEAR_TO && month - 1 === CURRENT_MONTH
                  ? Math.min(days, birthdateDay, CURRENT_DAY)
                  : Math.min(days, birthdateDay);

              if (birthdateDay != newDays) {
                setBirthdateDay(newDays);
              }

              setBirthdateMonth(month);
            }}
            selectedOptions={[
              {
                value: birthdateMonth,
                label: createMonthMenuItems(intl)[birthdateMonth - 1].label,
                id: birthdateMonth.toString(),
              },
            ]}
            options={createMonthMenuItems(intl)}
          />
        </Box>

        <Box onClick={onFieldTouch} className={styles.yearContainer}>
          <DateSelector
            hasError={hasError}
            onChange={(selected) => {
              const year = selected[0].value;
              const days = daysInMonth(year, birthdateMonth);

              const newDays =
                year === YEAR_TO && month - 1 === CURRENT_MONTH
                  ? Math.min(days, birthdateDay, CURRENT_DAY)
                  : Math.min(days, birthdateDay);

              if (birthdateDay != newDays) {
                setBirthdateDay(newDays);
              }

              setBirthdateYear(year);
            }}
            selectedOptions={[
              {
                value: birthdateYear,
                label: birthdateYear.toString(),
                id: birthdateDay.toString(),
              },
            ]}
            options={createYearsMenuItems()}
          />
        </Box>
      </Box>
    </Box>
  );
};

export const Component = memo(BirthdayFields);
