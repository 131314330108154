import { FunctionComponent, PropsWithChildren } from 'react';

import Box from 'src/rewardis-kit/components/box';
import { ThemeProvider } from 'src/rewardis-kit/theme-provider';

import DebugPanel from 'src/components/DebugPanel';
import SwitchLanguage from 'src/components/SwitchLanguage';
import Footer from 'src/layouts/_MainFooter';
import CookieConsent from 'src/components/CookieConsent';

import { ReactComponent as Logo } from 'src/assets/logo-desktop.svg';
import { ReactComponent as LogoMobile } from 'src/assets/logo-mobile.svg';
import EarningUserStream from 'src/components/EarningUserStream';
import { useDesignParams } from 'src/hooks/useDesign';
import { LANDING_ID } from 'src/constants/landings';
import useClasses from './get-styles';

const LayoutLanding: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { designId } = useDesignParams();
  const isEarningsHidden = [
    LANDING_ID.NEW_REMOVED_STREAM,
    LANDING_ID.NEW_REMOVED_STREAM_AND_GOOGLE_AUTH_TOP,
    LANDING_ID.NEW_REMOVED_STREAM_AND_GOOGLE_AUTH_TOP_AND_TC_MODAL,
    LANDING_ID.NEW_NEW,
    LANDING_ID.NEW,
    LANDING_ID.SHORT_FORM,
    LANDING_ID.OLD,
    LANDING_ID.HIDE_GOOGLE_AUTH_PART_DATA,
    LANDING_ID.HIDE_GOOGLE_AUTH_FULL_DATA,
  ].includes(designId);
  const theme = [
    LANDING_ID.OLD,
    LANDING_ID.NEW_NEW,
    LANDING_ID.SHORT_FORM,
    LANDING_ID.NEW,
    LANDING_ID.HIDE_GOOGLE_AUTH_FULL_DATA,
    LANDING_ID.HIDE_GOOGLE_AUTH_PART_DATA,
  ].includes(designId)
    ? 'landingNew'
    : 'landing';
  const styles = useClasses();

  return (
    <ThemeProvider themeName={theme}>
      <DebugPanel />
      <Box className={styles.layoutWrapper}>
        <Box className={styles.layoutHeaderWrapper} component="header">
          <Box className={styles.layoutHeaderLogo}>
            <Logo />
          </Box>
          <Box className={styles.layoutHeaderLogoMobile}>
            <LogoMobile />
          </Box>
          <Box className={styles.layoutHeaderLangSwitcher}>
            <SwitchLanguage isRedesigned />
          </Box>
        </Box>
        <Box>
          {!isEarningsHidden && (
            <EarningUserStream source="earning-user-stream" />
          )}
        </Box>
        {children}
        <Footer />
      </Box>
      <CookieConsent />
    </ThemeProvider>
  );
};

export default LayoutLanding;
