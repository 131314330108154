import { FC, memo } from 'react';
import { InputProps } from 'src/rewardis-kit/components/input/types';
import { useClasses } from 'src/rewardis-kit/components/input/get-styles';
import { mergeClasses } from '@griffel/react';
import Typography from 'src/rewardis-kit/components/typography';
import { TypographyVariants } from 'src/rewardis-kit/components/typography/types';

const InputComponent: FC<InputProps> = ({ className, ...props }) => {
  const classes = useClasses();
  const hasError = props.error || props.errorText;
  const hasSuccess = props.success || props.successText;
  const statusText = props.errorText || props.successText || null;

  return (
    <div className={classes.baseWrapperStyles}>
      {props.iconBefore && (
        <span className={mergeClasses(classes.baseIcon, classes.leftIcon)}>
          {props.iconBefore}
        </span>
      )}

      {props.iconAfter && (
        <span className={mergeClasses(classes.baseIcon, classes.rightIcon)}>
          {props.iconAfter}
        </span>
      )}
      <input
        className={mergeClasses(
          classes.baseInputStyles,
          hasError && classes.withError,
          hasSuccess && classes.withSuccess,
          props.iconAfter && classes.withIconRight,
          props.iconBefore && classes.withIconLeft,
          className
        )}
        {...props}
      />
      {statusText && (
        <span className={mergeClasses(classes.baseStatusText)}>
          <Typography
            className={mergeClasses(
              props.errorText && classes.errorStatusText,
              props.successText && classes.successStatusText
            )}
            variant={TypographyVariants.body2}
          >
            {statusText}
          </Typography>
        </span>
      )}
    </div>
  );
};

export default memo(InputComponent);
