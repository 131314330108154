import { RemoteDataResult } from 'src/lib/remoteData';
import { request } from 'src/lib/request';
import { TASK_TYPE } from 'src/constants/enums';
import * as storage from 'src/lib/storage';

export function getOffer(
  offerId: number
): Promise<RemoteDataResult<{ prize: Prize }>> {
  return request({
    url: `v1/prizes/${offerId}`,
    method: 'GET',
  });
}

export function getLastUserEarnings(): Promise<
  RemoteDataResult<UserEarning[]>
> {
  return request({
    url: `v1/user_earning/last`,
    method: 'GET',
  });
}

export function getOfferDetails(
  offerId: number
): Promise<RemoteDataResult<Task>> {
  return request({
    url: `v1/offers/${offerId}/get`,
    method: 'GET',
  });
}

export function getTasks(
  type: TASK_TYPE,
  language: string
): Promise<RemoteDataResult<TaskContainer>> {
  const params: Record<any, any> = {
    language,
    order_by: 'priority_required',
    dest: 'ASC',
    page_size: 15,
  };

  switch (type) {
    case TASK_TYPE.OFFER:
      params['type_not'] = [TASK_TYPE.SURVEY, TASK_TYPE.OWN_SURVEY];
      break;
    case TASK_TYPE.SURVEY:
      params['type'] = [TASK_TYPE.SURVEY, TASK_TYPE.OWN_SURVEY];
      break;
    case TASK_TYPE.POST_REG:
      params['type'] = [TASK_TYPE.POST_REG];
      break;
  }

  return request({
    url: 'v2/offers/',
    method: 'GET',
    params,
  });
}

export function getSubID(
  userId: number,
  offerId: number,
  route?: string
): Promise<RemoteDataResult<{ click_id: number }>> {
  return request({
    url: 'v1/offers_click/get',
    method: 'GET',
    params: {
      user_id: userId,
      offer_id: offerId,
      task_id: offerId,
      route: route,
    },
  });
}

const LAST_APPLIED = 'last_applied_tasks';
export const BLOCKING_DURATION = 2 * 60;
export const LAST_APPLIED_TTL = BLOCKING_DURATION * 1000;

type BlockingTime = {
  id: number;
  timestamp: number;
};
export const LastAppliedTasks = {
  getTimeIfExist: (id: number): BlockingTime | undefined => {
    const lastApplied = storage.get(LAST_APPLIED, []) as BlockingTime[];

    return lastApplied.find((item) => item.id === id);
  },
  getAllExcludedOne: (id: number) => {
    const lastApplied = storage.get(LAST_APPLIED, []) as BlockingTime[];

    return lastApplied.filter((item) => item.id !== id);
  },
  setBlockingTime: (id: number) => {
    const filtered = LastAppliedTasks.getAllExcludedOne(id);

    filtered.push({ id, timestamp: new Date().getTime() });
    storage.set(LAST_APPLIED, filtered);
  },
  getBlockingTimeLeft: (id: number) => {
    const currentItem = LastAppliedTasks.getTimeIfExist(id);
    if (!currentItem) {
      return 0;
    }
    const diff = new Date().getTime() - currentItem.timestamp;
    return diff < LAST_APPLIED_TTL ? LAST_APPLIED_TTL - diff : 0;
  },
};
