import { PropsWithChildren, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import * as gtm from 'src/lib/gtm';
import * as storage from 'src/lib/storage';
import * as TokenService from 'src/services/TokenService';
import * as AuthService from 'src/services/AuthService';
import * as Sentry from '@sentry/browser';

import { State, initialState, profileState } from 'src/recoil/profile';
import { smartlookIdentification } from 'src/services/GoogleTagManagerService';

export { STORAGE_PROFILE_KEY } from 'src/recoil/profile';

function setGTM(profile: State) {
  if (!profile) {
    return;
  }
  if (profile.geo) {
    gtm.set('geo', profile.geo);
  }
  if (profile.user_group) {
    gtm.set('user_group', profile.user_group);
  }
  if (profile.gender === 1) {
    gtm.set('gender', 'male');
  }
  if (profile.gender === 2) {
    gtm.set('gender', 'female');
  }
}

setGTM(initialState);

export function useProfileState() {
  const profile = useRecoilValue(profileState);

  return {
    profile,
  };
}

export function useProfileDispatch() {
  const [profile, setProfileState] = useRecoilState(profileState);

  const setProfile = (profile: ProfileUI) => {
    Sentry.setUser({ id: profile.id, email: profile.id.toString() });
    setGTM(profile);
    setProfileState(profile);
  };

  const setProfileField = (fieldName: keyof Profile, fieldValue: any) => {
    const updated = {
      ...profile,
      [fieldName]: fieldValue,
    } as ProfileUI;

    setProfileState(updated);
  };

  const logout = async () => {
    await AuthService.logout();
    TokenService.resetToken();
    setProfileState(null);
  };

  return {
    logout,
    setProfileField,
    setProfile,
  };
}

export default function ProfileProvider({ children }: PropsWithChildren) {
  const { profile } = useProfileState();

  useEffect(() => {
    if (
      profile?.has_metric &&
      !window.isYandexMetrikaInjected &&
      window.injectYandexMetrika
    ) {
      window.injectYandexMetrika();
    }
  }, [Boolean(profile)]);

  useEffect(() => {
    if (profile) {
      smartlookIdentification(profile.id);
    }
  }, [Boolean(profile)]);

  return null;
}
