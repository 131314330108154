import { CSSProperties, PropsWithChildren } from 'react';

export enum TypographyVariants {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
  subtitle1 = 'subtitle1',
  subtitle2 = 'subtitle2',
  body1 = 'body1',
  body2 = 'body2',
}

export type VariantMapping = Record<TypographyVariants, string>;

export interface TypographyProps extends PropsWithChildren {
  align?: CSSProperties['textAlign'];
  color?: CSSProperties['color'];
  classes?: CSSProperties;
  className?: string;
  gutterBottom?: boolean;
  noWrap?: boolean;
  paragraph?: boolean;
  variant: TypographyVariants;
  variantMapping?: VariantMapping;
  fontFamily?: string;
  fontWeight?: string;
  lineHeight?: string;
  fontSize?: string;
  letterSpacing?: string;
}
