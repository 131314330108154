import Box from 'src/rewardis-kit/components/box';
import useClasses from './get-styles';
import { ReactComponent as GenderMenIcon } from 'src/assets/icons/gender-men.svg';
import { ReactComponent as GenderWomenIcon } from 'src/assets/icons/gender-women.svg';
import { mergeClasses } from '@griffel/react';
import { memo } from 'react';

interface GenderControlProps {
  label: string;
  type: 'male' | 'female';
  isSelected: boolean;
  hasError: boolean;
  handleClick: () => void;
  id: string;
}

const ICON_SIZE = 24;
const iconSizes = {
  height: ICON_SIZE,
  width: ICON_SIZE,
};

const components = {
  male: <GenderMenIcon {...iconSizes} />,
  female: <GenderWomenIcon {...iconSizes} />,
};

const Index = (props: GenderControlProps) => {
  const { type, label, isSelected, hasError, handleClick, id } = props;

  const styles = useClasses();

  const errorClass = hasError ? styles.withError : '';
  const selectedClass = isSelected
    ? styles.withSelected
    : styles.withInSelected;

  const classes = mergeClasses(styles.root, selectedClass, errorClass);

  return (
    <Box
      className={classes}
      tabIndex={0}
      component="div"
      onKeyDown={handleClick}
      onClick={handleClick}
      id={id}
    >
      {components[type] ?? null}
      {label}
    </Box>
  );
};

export default memo(Index);
