import { makeStyles, shorthands } from '@griffel/react';

export default makeStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    ...shorthands.padding('2px', '0'),
  },
  withWhiteText: {
    color: 'black',
    '@media (min-width: 900px)': {
      color: 'white',
    },
  },
  registrationFormWrapper: {
    '@media screen and (min-width: 900px)': {
      // TODO: need add breakpoint from config
      minHeight: '300px',
    },
  },
});
