import { makeStyles, shorthands } from '@griffel/react';

export default makeStyles({
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    ...shorthands.gap('12px'),
    ...shorthands.flex(4),
  },
  label: {
    ...shorthands.flex(1),
    fontSize: '1em',
    fontWeight: 500,
    '@media screen and (max-width: 900px)': {
      // TODO: need add breakpoint from config
      color: 'rgb(7, 9, 29)',
      fontSize: '0.75em',
      ...shorthands.flex(1),
    },
  },
  control: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    ...shorthands.gap('12px'),
  },
});
