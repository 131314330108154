import { makeStyles, shorthands } from '@griffel/react';

const COLOR_SELECTED = 'var(--palette--primary-main)';
const COLOR_NOT_SELECTED = '#EFEFF5';
const TEXT_COLOR_NOT_SELECTED = '#000';
const TEXT_COLOR_SELECTED = '#FFF';

const borderSize = 2;

export default makeStyles({
  root: {
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1rem',
    fontWeight: '400',
    height: '42px',
    ...shorthands.flex(1),
    ...shorthands.borderRadius('7px'),
    ...shorthands.border(`${borderSize}px`, 'solid', '#fff'),

    ':hover': {
      cursor: 'pointer',
    },

    '@media screen and (max-width: 900px)': {
      // TODO: need add breakpoint from config
      fontSize: '0.75em',
    },
  },

  withSelected: {
    color: TEXT_COLOR_SELECTED,
    backgroundColor: COLOR_SELECTED,
    ...shorthands.borderColor(COLOR_SELECTED),

    '& svg path': {
      fill: TEXT_COLOR_SELECTED,
    },
    '& svg circle': {
      stroke: TEXT_COLOR_SELECTED,
    },
  },

  withInSelected: {
    color: TEXT_COLOR_NOT_SELECTED,
    backgroundColor: COLOR_NOT_SELECTED,
    ...shorthands.borderColor(COLOR_NOT_SELECTED),

    '& svg path': {
      fill: TEXT_COLOR_NOT_SELECTED,
    },
    '& svg circle': {
      stroke: TEXT_COLOR_NOT_SELECTED,
    },
  },

  withError: {
    ...shorthands.borderColor('var(--palette--error-color)'),
  },
});
