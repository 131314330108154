import * as yup from 'yup';
import { memo, useEffect } from 'react';
import { useField, FieldHookConfig } from 'formik';
import {
  useReCaptchaContext,
  useRecaptchaBadge,
} from 'src/providers/ReCaptchaProvider';
import { ValidationSchemaProps } from '../typings';

export const initialValue = '';

export const createValidationSchema = ({
  intl,
  recaptchaVersion,
}: ValidationSchemaProps) =>
  recaptchaVersion === 3
    ? yup.string().required(
        intl.formatMessage({
          id: 'form.validation.required',
          defaultMessage: 'This field is required',
        })
      )
    : yup.string();

interface ReCaptchaV3Props {
  action: string;
  callback: (token: string) => void;
}

function ReCaptchaV3({ action, callback }: ReCaptchaV3Props) {
  const { loaded, siteKeyV3, readyToInit } = useReCaptchaContext();
  useRecaptchaBadge();

  if (!siteKeyV3) {
    throw new Error(
      'The prop "siteKeyV3" must be set on the ReCaptchaProvider before using the ReCaptchaV3 component'
    );
  }

  useEffect(() => {
    if (!loaded || !siteKeyV3 || !readyToInit) return;

    console.log('loading recaptcha');
    let isUnmounted = false;

    grecaptcha.execute(siteKeyV3, { action }).then((token: string): void => {
      // do not attempt to set state or invoke the callback
      // if the component is being unmounted
      console.log('recaptcha callback called');
      if (isUnmounted) {
        return;
      }
      callback(token);
    });

    return () => {
      isUnmounted = true;
    };
  }, [loaded, siteKeyV3, readyToInit]);

  return null;
}

interface RecaptchaV3FieldProps {
  action: string;
  callback?: () => void;
}

const RecaptchaV3Component = ({
  action,
  callback,
  ...props
}: RecaptchaV3FieldProps & FieldHookConfig<string>) => {
  const [, , helpers] = useField(props);
  const { setValue } = helpers;

  const v3Callback = (token: string) => {
    callback?.();
    setValue(token);
  };

  return <ReCaptchaV3 action={action} callback={v3Callback} />;
};

export const Component = memo(RecaptchaV3Component);