import { makeStyles, shorthands } from '@griffel/react';

export default makeStyles({
  googleButton: {
    display: 'flex',
    alignItems: 'center',
    ...shorthands.padding('4px', '8px'),
    backgroundColor: '#EFEFF5',
    ...shorthands.border('1px', 'solid', '#AAA'),
    fontSize: '13px',
    lineHeight: '20px',
    textTransform: 'none',
    height: '40px',
    color: '#000',
    ':hover': {
      backgroundColor: '#fff',
    },
  },
  googleButtonMobile: {
    display: 'flex',
    alignItems: 'center',
    ...shorthands.border('1px', 'solid', '#DDDDE8'),
    ...shorthands.borderRadius('16px'),
    color: 'var(--palette--primary-main)',
    ...shorthands.gap('0.5px'),
    ...shorthands.padding('0', '2px'),
    marginX: 'auto',

    '@media screen and (min-width: 600px)': {
      // TODO: need add breakpoint from config
      display: 'none',
    },
  },
  googleButtonStandard: {
    ...shorthands.flex(1),
    backgroundColor: 'rgb(42, 44, 57)',
    width: '100%',
    color: '#fff',

    '&:hover': {
      backgroundColor: 'rgba(42, 44, 57, 0.8)',
    },
  },
  withIsMobile: {
    '@media screen and (min-width: 600px)': {
      // TODO: need add breakpoint from config
      display: 'none',
    },
  },
  withIsNotMobile: {
    '@media screen and (max-width: 600px)': {
      // TODO: need add breakpoint from config
      display: 'none',
    },
  },
});
