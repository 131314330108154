import React, {
  createContext,
  useContext,
  ReactNode,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { Theme } from 'src/rewardis-kit/types';
import { defaultTheme } from 'src/rewardis-kit/themes/default-theme';

const ThemeContext = createContext<{
  theme: Theme;
  setTheme: (theme: Theme) => void;
}>({
  theme: defaultTheme,
  setTheme: () => {},
});

const loadTheme = async (themeName: string) => {
  try {
    await import(`../themes/${themeName}.css`);
  } catch (err) {
    console.error(`Failed to load ${themeName} theme`, err);
  }
};

export const ThemeProvider = ({
  children,
  outputTheme,
  themeName,
}: {
  children: ReactNode;
  outputTheme?: Partial<Theme>;
  themeName: string;
}) => {
  const [theme, setTheme] = useState({ ...defaultTheme, ...outputTheme });
  const value = useMemo(() => ({ theme, setTheme }), [theme]);

  useEffect(() => {
    loadTheme(themeName).then();
  }, []);

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
