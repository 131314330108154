import {
  BROWSER_MAP,
  MOBILES_REGEXP,
  OPERATIONS_SYSTEMS,
  OPERATIONS_SYSTEMS_MAP,
} from './constants';
import { findKey } from './utils';

class InApp {
  ua = '';

  constructor(useragent: string) {
    this.ua = useragent;
  }

  get browser(): string {
    return findKey(BROWSER_MAP, (regex) => regex.test(this.ua)) || 'other';
  }

  get platform(): OPERATIONS_SYSTEMS | string {
    return (
      findKey(OPERATIONS_SYSTEMS_MAP, (regex) => regex.test(this.ua)) || 'other'
    );
  }

  get isMobile(): boolean {
    return MOBILES_REGEXP.test(this.ua) || false;
  }

  get isDesktop(): boolean {
    return !this.isMobile;
  }

  get isInApp(): boolean {
    const rules = [
      'WebView',
      '(iPhone|iPod|iPad)(?!.*Safari/)',
      'Android.*(wv)',
    ];
    const regex = new RegExp(`(${rules.join('|')})`, 'ig');

    return Boolean(this.ua.match(regex));
  }
}

export default InApp;
