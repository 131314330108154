export const ROUTE_HOMEPAGE = '/';
export const ROUTE_AUTH = '/auth/:authToken';
export const ROUTE_FORTUNE = '/fortune';
export const ROUTE_SIGNIN = '/signin';
export const ROUTE_SIGNUP = '/signup';
export const ROUTE_RESET_PASSWORD = '/reset-password';
export const ROUTE_TASKS = '/tasks';
export const ROUTE_PRIZE_DRAWING = '/prize-drawings';
export const ROUTE_CONFIRMATION_BASE = '/confirmation';
export const ROUTE_CONFIRMATION = `${ROUTE_CONFIRMATION_BASE}/:confirmationToken`;
export const ROUTE_ACCOUNT_PROFILE = '/account/profile';
export const ROUTE_ACCOUNT_LOGOUT = '/account/logout';
export const ROUTE_OFFERS = '/offers';
export const ROUTE_SURVEYS = '/surveys';
export const ROUTE_PAGE_FAQ = '/faq';
export const ROUTE_PAGE_OPT_OUT = '/opt-out';
export const ROUTE_PAGE_PRIVACY = '/privacy';
export const ROUTE_PAGE_COOKIE = '/cookie-policy';
export const ROUTE_PAGE_TERMS = '/terms-and-conditions';
export const ROUTE_WITHDRAW = '/withdraw';
export const ROUTE_RATING = '/rating';

export const ROUTE_POSTREG_TASKS = '/postreg';
export const ROUTE_WINNERS = '/winners';
export const ROUTE_SMARTLINK = 'smartlink';
export const ROUTE_SURVEY_DETAIL = '/surveys/:id';
