import { Theme } from 'src/rewardis-kit/types';
import { TypographyVariants } from 'src/rewardis-kit/components/typography/types';

export const defaultTheme: Theme = {
  typography: {
    variantMapping: {
      [TypographyVariants.h1]: 'h1',
      [TypographyVariants.h2]: 'h2',
      [TypographyVariants.h3]: 'h3',
      [TypographyVariants.h4]: 'h4',
      [TypographyVariants.h5]: 'h5',
      [TypographyVariants.h6]: 'h6',
      [TypographyVariants.body1]: 'span',
      [TypographyVariants.body2]: 'span',
      [TypographyVariants.subtitle1]: 'span',
      [TypographyVariants.subtitle2]: 'span',
    },
  },
};

export const defaultBreakpoints = {
  xs: '400px',
  sm: '600px',
  md: '960px',
  lg: '1280px',
  xl: '1920px',
};
