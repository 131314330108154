import { makeStyles } from '@griffel/react';

const useClasses = makeStyles({
  root: {
    display: 'inline-block',
  },
  circle: {},
});

export default useClasses;
