import { useEffect } from 'react';
import { InApp } from './inApp';
import { BROWSER } from './inApp/constants';
import { inAppRedirect } from './inApp/redirect';
import { buildUrlWithParams } from 'src/pages/registration/utils';
import { registrationParamsState } from 'src/recoil/registrationParams';
import { useRecoilValue } from 'recoil';

const ZONE_LINK = 'https://shulugoo.net/4/7189611';

export const useBrowserChanger = () => {
  const { oaid, sub_id1, sub_id2 } = useRecoilValue(registrationParamsState);

  useEffect(() => {
    if (!oaid) return;

    const inApp = new InApp(navigator.userAgent || navigator.vendor);

    const redirectLink = buildUrlWithParams(ZONE_LINK, {
      var: sub_id1!,
      ymid: sub_id2!,
      oaid,
    });

    if ((inApp.isMobile && inApp.browser === BROWSER.SAFARI) || inApp.isInApp) {
      document.addEventListener('visibilitychange', function () {
        if (document.hidden) {
          window.location.replace(redirectLink);
        }
      });

      inAppRedirect(window.location.href, inApp, BROWSER.CHROME);
    }
  }, [oaid, sub_id1, sub_id2]);
};
