import { atom, selector, useRecoilState } from 'recoil';
import { RECAPTCHA_V2_KEY, RECAPTCHA_V3_KEY } from 'src/constants/app';

type ConstantFields = {
  siteKeyV2?: string;
  siteKeyV3?: string;
};

type MutableFields = {
  loaded: boolean;
  readyToInit: boolean;
};

const RecaptchaKeysAtom = atom<ConstantFields>({
  key: 'recaptchaKeys',
  default: {
    siteKeyV2: RECAPTCHA_V2_KEY,
    siteKeyV3: RECAPTCHA_V3_KEY,
  },
});

const RecaptchaStateAtom = atom<MutableFields>({
  key: 'recaptcha',
  default: {
    loaded: false,
    readyToInit: false,
  },
});

export const recaptchaFullStateSelector = selector<
  MutableFields & ConstantFields
>({
  key: 'recaptchaReadonly',
  get: ({ get }) => {
    return {
      ...get(RecaptchaKeysAtom),
      ...get(RecaptchaStateAtom),
    };
  },
});

export const useRecaptchaMutation = () => {
  const [_, set] = useRecoilState(RecaptchaStateAtom);

  return (newValue: Partial<MutableFields>) => {
    set((prevState) => ({
      ...prevState,
      ...newValue,
    }));
  };
};
