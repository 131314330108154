import { makeStyles, shorthands } from '@griffel/react';

export const useClasses = makeStyles({
  base: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    cursor: 'pointer',
    ...shorthands.border(0),
    ...shorthands.borderRadius('var(--buttons--border-radius)'),
    ...shorthands.transition('all', '0.3s', '0', 'ease'),
    backgroundColor: 'rgb(222,222,222)',
    color: 'rgb(0,0,0)',

    ':disabled': {
      cursor: 'default',
      backgroundColor: 'rgb(239,239,239)',
      color: 'rgb(171,171,171)',

      ':hover': {
        filter: 'none',
      },
    },

    ':hover': {
      filter: 'var(--buttons--hover-darken)',
    },
  },
  withPrimaryColor: {
    backgroundColor: 'var(--palette--primary-main)',
    color: 'var(--palette--primary-color)',
    boxShadow:
      'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
  },
  withSecondaryColor: {
    backgroundColor: 'var(--palette--secondary-main)',
    color: 'var(--palette--secondary-color)',
    boxShadow:
      'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
  },

  withOutlinedVariant: {},
  withContainedVariant: {},
  withTextVariant: {},

  withSmSize: {
    ...shorthands.padding('4px', '5px', '4px', '5px'),
    fontSize: '0.8125rem',
  },
  withMdSize: {
    ...shorthands.padding('5px', '15px', '5px', '15px'),
    fontSize: '0.875rem',
  },
  withLgSize: {
    ...shorthands.padding('6px', '16px'),
    height: '42px',
    fontSize: '0.9375rem',
  },

  iconBase: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconRight: {
    marginLeft: '4px',
  },
  iconLeft: {
    marginRight: '4px',
  },
});
