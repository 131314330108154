import * as yup from 'yup';
import { FieldHookConfig, useField } from 'formik';
import { IntlShape, useIntl } from 'react-intl';
import { FieldCheckIcon } from '../../CheckIcon';
import Input from 'src/rewardis-kit/components/input';
import { useEffect } from 'react';
import { useRecaptchaMutation } from 'src/recoil/recaptcha';

export const initialValue = '';

const MIN_LIMIT = 5;
const MAX_LIMIT = 128;

interface ValidationSchemaProps {
  intl: IntlShape;
}

export const createValidationSchema = ({ intl }: ValidationSchemaProps) =>
  yup
    .string()
    .trim()
    .email(
      intl.formatMessage({
        id: 'form.validation.email',
        defaultMessage: 'Enter a valid e-mail',
      })
    )
    .required(
      intl.formatMessage({
        id: 'form.validation.required.email',
        defaultMessage: 'This field is required',
      })
    )
    .min(
      MIN_LIMIT,
      intl.formatMessage(
        {
          id: 'form.validation.min',
          defaultMessage: 'The minimum field length is {value}',
        },
        { value: MIN_LIMIT }
      )
    )
    .max(
      MAX_LIMIT,
      intl.formatMessage(
        {
          id: 'form.validation.max',
          defaultMessage: 'The minimum field length is {value}',
        },
        { value: MAX_LIMIT }
      )
    );

export function Component(props: FieldHookConfig<string>) {
  const [field, meta, helpers] = useField(props);
  const intl = useIntl();
  const mutateRecaptcha = useRecaptchaMutation();

  const onChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    helpers.setValue(e.target.value.trim());
  };

  useEffect(() => {
    if (meta.value?.length) {
      mutateRecaptcha({ readyToInit: true });
    }
  }, [Boolean(meta.value)]);

  return (
    <Input
      {...field}
      error={!!meta.error && meta.touched}
      success={!meta.error && meta.touched}
      onChange={onChange}
      placeholder={intl.formatMessage({
        id: 'form.email',
        defaultMessage: 'E-mail',
      })}
      id={field.name}
      iconAfter={<FieldCheckIcon meta={meta} />}
    />
  );
}
