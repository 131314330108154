import { ElementType, FC } from 'react';
import { LinkProps } from 'src/rewardis-kit/components/link/types';
import Typography from 'src/rewardis-kit/components/typography';
import { TypographyVariants } from 'src/rewardis-kit/components/typography/types';
import useClasses from './get-styles';
import { mergeClasses } from '@griffel/react';

const Link = <T extends ElementType = 'a'>(props: LinkProps<T>) => {
  const { className, variant, children, component, ...rest } = props;
  const styles = useClasses();
  const Component = component || 'a';

  return (
    <Component {...rest} className={mergeClasses(styles.root, className)}>
      {variant ? (
        <Typography variant={variant || TypographyVariants.body2}>
          {children}
        </Typography>
      ) : (
        children
      )}
    </Component>
  );
};

export default Link;
