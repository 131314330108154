import { useTheme } from 'src/rewardis-kit/theme-provider';
import { TypographyProps } from 'src/rewardis-kit/components/typography/types';
import React, {
  FunctionComponent,
  HTMLAttributes,
  PropsWithChildren,
} from 'react';
import {
  getStyles,
  useClasses,
  useSpecifiedClasses,
} from 'src/rewardis-kit/components/typography/get-styles';
import { mergeClasses } from '@griffel/react';

const TypographyComponent: FunctionComponent<TypographyProps> = (props) => {
  const { theme } = useTheme();
  const classes = useClasses();
  const specifiedClasses = useSpecifiedClasses();

  const dynamicStyles = getStyles(props) as React.CSSProperties;

  const Component =
    (props.variantMapping || theme.typography.variantMapping)?.[
      props?.variant
    ] ||
    ('span' as unknown as FunctionComponent<
      PropsWithChildren & HTMLAttributes<HTMLDivElement>
    >);

  return (
    <Component
      style={dynamicStyles}
      className={mergeClasses(
        classes.root,
        specifiedClasses[props.variant],
        props.className
      )}
    >
      {props.children}
    </Component>
  );
};

export default TypographyComponent;
