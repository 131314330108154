import { makeStyles, shorthands } from '@griffel/react';

export default makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  partnerWrapper: {
    ...shorthands.borderTop('1px', 'solid', '#CCC'),
    ...shorthands.padding('1rem', '0', '0', '0'),
    ...shorthands.margin('1rem', '0', '0', '0'),
  },
  text: {
    ...shorthands.padding('1rem', '0'),
  },
  headerName: {
    display: 'block',
    ...shorthands.margin('0'),
    ...shorthands.padding('1rem', '0'),
  },
});
