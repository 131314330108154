import { BROWSER } from './constants';
import {
  getChangeBrowserLinkFromChromium,
  getChangeBrowserLinkFromIOS,
} from './utils';
import { InApp } from 'src/hooks/useBrowserChanger/inApp/index';

export const inAppRedirect = (
  url: string,
  inApp: InApp,
  toBrowser: BROWSER
) => {
  try {
    const fromBrowser = inApp.browser;
    const preparedUrl = new URL(url);
    preparedUrl.searchParams.append('sub_id_7', fromBrowser);

    if (fromBrowser === BROWSER.SAFARI) {
      return window.location.replace(
        getChangeBrowserLinkFromIOS(preparedUrl, toBrowser)
      );
    }

    return window.location.replace(
      getChangeBrowserLinkFromChromium(preparedUrl, toBrowser)
    );
  } catch (e) {
    console.warn(e);
  }
};
