import { makeStyles, shorthands } from '@griffel/react';

export default makeStyles({
  earningUserItemContainer: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    ...shorthands.padding('8px'),
    ...shorthands.borderRadius('8px'),
    animationDuration: '.5s',
    animationName: 'zoomIn',
    backgroundColor: '#fff',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  withInvertEarningUserItemContainer: {
    backgroundColor: 'rgb(29, 31, 52)',
  },
  earningUserItemLeft: {
    width: '30px',
    position: 'relative',
  },
  earningUserItemLeftIcon: {
    width: '30px',
    height: '30px',
    ...shorthands.borderRadius('8px'),
  },
  earningUserItemLeftFlagWrapper: {
    position: 'absolute',
    right: '0',
    bottom: '-6px',
    fontSize: '12px',
    lineHeight: '12px',
    ...shorthands.borderRadius('16px'),
    width: '16px',
    height: '16px',
    ...shorthands.overflow('hidden'),
  },
  earningUserItemRight: {
    minWidth: '80px',
    marginLeft: '8px',
    display: 'flex',
    flexDirection: 'column',
  },
  earningUserItemRightName: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '18px',
    whiteSpace: 'nowrap',
    color: 'rgb(7, 9, 29)',
  },
  withInvertEarningUserItemRightName: {
    color: 'rgb(255, 255, 255)',
  },
  earningUserItemRightTimeAgo: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '12px',
    color: 'rgb(7, 9, 29)',
    whiteSpace: 'nowrap',
  },
  withInvertEarningUserItemRightTimeAgo: {
    color: 'rgb(104, 106, 119)',
  },
  earningUserItemAmountBox: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#fff',
    ...shorthands.borderRadius('8px'),
    alignItems: 'center',
    ...shorthands.padding('4px'),
    marginLeft: '8px',
  },
  withInvertErningUserItemAmountBox: {
    backgroundColor: 'rgb(19, 21, 38)',
  },
  earningUserItemAmount: {
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '13px',
    ...shorthands.padding('4px'),
    color: 'rgb(7, 9, 29)',
  },
  withInvertEarningUserItemAmount: {
    color: 'rgb(255, 255, 255)',
  },
  earnWrapper: {
    ...shorthands.padding('8px'),
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'scroll',
    overflowY: 'hidden',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    scrollbarWidth: 'none',
    '-ms-overflow-style': 'none',
    // [theme.breakpoints.down('sm')]: {
    //   paddingY: 0,
    // },
  },
  earn: {
    display: 'flex',
    flexDirection: 'row',
    ...shorthands.gap('8px'),
    '@keyframes zoomIn': {
      '0%': {
        opacity: 0,
        transform: 'scale3d(.3, .3, .3)',
      },
      '100%': {
        opacity: 1,
      },
    },
  },
});
