import { makeStyles, shorthands } from '@griffel/react';

const borderSize = 2;
const defaultPadding = 14;
const withIconPadding = 38;

export const useClasses = makeStyles({
  baseWrapperStyles: {
    position: 'relative',
    width: '100%',
  },
  baseInputStyles: {
    boxSizing: 'border-box',
    display: 'block',
    minWidth: '0px',
    width: '100%',
    height: '46px',
    backgroundColor: 'rgb(239, 239, 245)',
    color: '#000',
    ...shorthands.padding(`${defaultPadding}px`),
    ...shorthands.outline('0px'),
    ...shorthands.margin('0px'),
    ...shorthands.borderRadius('var(--inputs--border-radius)'),
    ...shorthands.border(`${borderSize}px`, 'solid', '#fff'),

    '&:hover:not(:disabled)': {
      ...shorthands.borderColor('#000'),
    },
  },
  withSuccess: {
    ...shorthands.border(
      `${borderSize}px`,
      'solid',
      'var(--palette--success-color)'
    ),
  },
  withIconLeft: {
    paddingLeft: `${withIconPadding}px`,
  },
  withIconRight: {
    paddingRight: `${withIconPadding}px`,
  },
  withError: {
    ...shorthands.border(
      `${borderSize}px`,
      'solid',
      'var(--palette--error-color)'
    ),
  },
  baseStatusText: {
    marginTop: '2px',
    position: 'absolute',
  },
  errorStatusText: {
    color: 'var(--palette--error-color)',
  },
  successStatusText: {
    color: 'var(--palette--success-color)',
  },
  baseIcon: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'absolute',
  },
  leftIcon: {
    left: '15px',
  },
  rightIcon: {
    right: '15px',
  },
});
