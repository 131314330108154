import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { ROUTE_HOMEPAGE } from 'src/constants/routes';
import Header from './_WithHeroHeader';

import logoImage from 'src/assets/logo-white.svg';

interface TwoColumnsHeroProps {
  title: string;
  subtitle: string;
  image: string;
  imageSize: string;
}

export default function WithHeroColumn(
  props: TwoColumnsHeroProps & PropsWithChildren
) {
  return (
    <Grid container direction="row" sx={{ minHeight: '100vh' }}>
      <Grid
        container
        item
        md={6}
        lg={5}
        alignItems="center"
        sx={(theme) => ({
          display: { xs: 'none', sm: 'none', md: 'flex' },
          background: `${theme.palette.background.default} no-repeat url("${props.image}") center/${props.imageSize}`,
          borderRadius: '0 60px 60px 0',
        })}
      >
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="space-between"
          height="100%"
          sx={{ paddingY: 6 }}
        >
          <Grid item>
            <Link to={ROUTE_HOMEPAGE}>
              <img src={logoImage} width="250" alt="Rewardis" />
            </Link>
          </Grid>
          <Grid item sx={{ textAlign: 'center' }}>
            <Typography
              variant="h3"
              sx={(theme) => ({
                color: theme.palette.text.secondary,
              })}
            >
              {props.title}
            </Typography>
            <Typography variant="h5" color="#9298A4">
              {props.subtitle}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        item
        sm={12}
        md={6}
        lg={7}
        alignItems="center"
        direction="column"
        justifyContent="center"
        sx={{ position: 'relative' }}
      >
        <Header hasColumn={true} />
        <Box
          sx={{
            borderRadius: 5,
            flex: 1,
            display: 'inline-flex',
            alignItems: 'center',
          }}
        >
          {props.children}
        </Box>
      </Grid>
    </Grid>
  );
}
