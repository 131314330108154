import { makeStyles, shorthands } from '@griffel/react';

export default makeStyles({
  closeIcon: {
    ...shorthands.borderRadius('16px'),
    position: 'absolute',
    right: '15px',
    top: '15px',
    width: '28px',
    height: '28px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  titleBox: {
    textAlign: 'center',
    color: 'rgb(7, 9, 29)',
    ...shorthands.padding('16px', '24px'),
  },
  contentBox: {
    ...shorthands.padding('0', '24px', '16px'),
  },
});
