import { makeStyles, shorthands } from '@griffel/react';

export default makeStyles({
  root: {
    position: 'relative',
  },
  container: {
    backgroundColor: '#fff',
    ...shorthands.borderRadius('12px'),
    ...shorthands.overflow('scroll', 'scroll'),
    color: '#000',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxHeight: '50vh',
    boxShadow:
      'rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px',
  },
  option: {
    cursor: 'pointer',
    fontSize: '1rem',
    minWidth: 'max-content',
    ...shorthands.padding('6px', '16px'),
    ':hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },

    '@media (max-width: 900px)': {
      ...shorthands.padding('6px', '12px'),
      fontSize: '0.75em',
    },
  },
  withSelected: {},
  selected: {
    textAlign: 'center',
    fontWeight: 500,
    cursor: 'pointer',
    caretColor: 'transparent',
    fontSize: '1rem',

    '@media screen and (max-width: 900px)': {
      // TODO: need add breakpoint from config
      fontSize: '0.75em',
    },
  },
});
