import React, { CSSProperties, FC, memo } from 'react';
import { FieldHookConfig, useField } from 'formik';
import { ValidationSchemaProps } from 'src/components/forms/RegistationForm/typings';
import * as yup from 'yup';

const stylesDefault: CSSProperties = {
  border: 'none',
  outline: 'none',
};

const stylesZIndex: CSSProperties = {
  ...stylesDefault,
  position: 'absolute',
  zIndex: -1,
};

const stylesMargin: CSSProperties = {
  ...stylesDefault,
  marginLeft: '-999px',
};

export const initialValue = '';

export const createValidationSchema = (params: ValidationSchemaProps) =>
  yup.string();

export const Component: FC<
  FieldHookConfig<string> & { position?: 'zIndex' | 'margin' }
> = memo((props) => {
  const [field] = useField(props);

  return (
    <input
      autoComplete="off"
      style={props.position === 'zIndex' ? stylesZIndex : stylesMargin}
      type={props.type}
      {...field}
    />
  );
});
