import { FunctionComponent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AmountTickets from 'src/components/AmountTickets';
import Modal from 'src/components/Modal';
import ModalTaskListButton from './ModalTaskListButton';
import { useCurrentTask } from 'src/hooks/useTasks';
import { useNavigate } from 'react-router-dom';
import { ROUTE_TASKS } from 'src/constants/routes';
import { isSuccess } from 'src/lib/remoteData';
import * as OffersService from 'src/services/OffersService';
import Loader from '../Loader';

type TaskModalContentProps = {
  source: string;
  task: Task;
  applyTask: (source: string, task: Task) => Promise<void>;
};

const TaskModalContent: FunctionComponent<TaskModalContentProps> = ({
  source,
  task,
  applyTask,
}) => {
  const intl = useIntl();

  return (
    <>
      <Box
        sx={(theme) => ({
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url(${task.image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundColor: '#bdbdbd',
          width: 320,
          height: 180,
          [theme.breakpoints.down('sm')]: {
            width: '100%',
          },
        })}
      />

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            background: '#DBF4EB',
            color: '#11B479',
            borderRadius: 2,
            paddingX: 1,
            paddingY: 0.5,
            display: 'inline-flex',
            alignItems: 'center',
          }}
        >
          {intl.formatMessage({
            id: 'task.award',
            defaultMessage: 'Award',
          })}
          <AmountTickets
            amount={'+' + task.amount}
            sx={{ paddingX: 2, fontSize: 14 }}
          />
        </Box>
      </Box>

      <Box sx={{ padding: 2, marginTop: 2 }}>
        <Typography
          sx={{
            marginBottom: 1,
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: '1.1em',
          }}
        >
          {task.title}
        </Typography>
        <Typography
          color="secondary"
          sx={{
            marginBottom: 1,
            wordBreak: 'break-all',
          }}
          dangerouslySetInnerHTML={{
            __html: task.description.replace(/(\r)?\n/g, '<br />'),
          }}
        />
        <ModalTaskListButton
          source={`${source}.task-modal`}
          task={task}
          applyTask={applyTask}
        />
      </Box>
    </>
  );
};

interface TaskModalProps {
  onClose: () => void;
}

export const TaskModal: FunctionComponent<
  TaskModalProps & TaskModalContentProps
> = (props) => {
  return (
    <Modal noPadding withClose isOpen handleClose={props.onClose}>
      <TaskModalContent {...props} />
    </Modal>
  );
};

type TaskRequestModalProps = TaskModalProps & {
  source: string;
  offerId: number;
  onClose?: () => void;
};

export const TaskRequestModal: FunctionComponent<TaskRequestModalProps> = (
  props
) => {
  const { currentTask, applyTask, clearCurrentTask, openTask } =
    useCurrentTask();

  const [isLoading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { offerId, source } = props;

  useEffect(() => {
    if (!offerId) {
      return;
    }

    (async () => {
      setLoading(true);

      const response = await OffersService.getOfferDetails(offerId);

      setLoading(false);

      if (!isSuccess(response)) {
        navigate(ROUTE_TASKS);
        return;
      }

      if (!response.data) {
        props?.onClose();
        navigate(ROUTE_TASKS);
        return;
      }

      await openTask(response.data);
    })();
  }, [offerId]);

  if (!offerId) {
    return null;
  }

  const handleClose = () => {
    props?.onClose();
    clearCurrentTask();
  };

  return (
    <Modal noPadding withClose isOpen handleClose={handleClose}>
      {isLoading ? (
        <Box sx={{ marginY: 3 }}>
          <Loader />
        </Box>
      ) : null}
      {currentTask ? (
        <TaskModalContent
          task={currentTask}
          applyTask={async (source: string, task: Task) => {
            applyTask(source, task);
            props?.onClose();
          }}
          source={source}
        />
      ) : null}
    </Modal>
  );
};
