export enum TASK_TYPE {
  OFFER = 'task',
  SURVEY = 'survey',
  OWN_SURVEY = 'own_survey',
  NO_REDIRECT = 'no_redirect',
  POST_REG = 'post_registration',
}
export enum TASK_NAME {
  CPX = 'cpx',
  SUBSCRIPTION = 'subscription',
  APLIXIR = 'aplixir',
  SAM = 'smart_active_media',
}

export enum POST_REG_TASK_TYPE {
  CONFIRMATION = 'confirmation_email',
  UPDATE_PROFILE = 'update_profile',
  HOMEPAGE = 'to_homepage',
}

export enum FEATURE {
  EMAIL_CONFIRMATION = 'confirmation',
  PUSH = 'push',
  WHEEL_OF_FORTUNE = 'wheel',
  POPUNDER = 'popunder',
  POPUP = 'popup',
  POPUP_IPP = 'popup_ipp',
  PUSH_REG = 'push_reg',
  PUSH_MEMBER = 'push_member',
  RECAPTCHA = 'recaptcha',
  APLIXIR = 'applixir',
  MARKER = 'marker',
  POST_REG = 'post_registration',
  SECOND_REG = 'second_registration',
  BACK_REG_POPUP = 'back_reg_popup',
  EARN_TICKET_REDIRECT = 'earn_ticket_redirect',
  PLACES_API = 'places_api',
  FORTUNE_POPUNDER = 'fortune_popunder',
  TUTORIAL_SWEEP = 'tutorial_sweep',
  FORTUNE_POPUP = 'fortune_popup',
  NOTIFICATIONS = 'notifications',
  FORTUNE_PRIZE_POPUP = 'fortune_prize_popup',
}

export enum SAVE_CLICK_ELEMENT_NAME {
  OFFER = 'offer',
  SWEEPSTAKE = 'sweepstake',
  MENU = 'menu',
  SURVEY = 'survey',
  PROFILE = 'profile_button',
  LOGOUT = 'logout_button',
  BOTTOM_MENU_BUTTON = 'bottom_menu',
  FOOTER = 'footer',
  WHEEL = 'wheel',
  WHEEL_SPIN = 'spin',
  EARNING_STREAM = 'earning_stream',
  EARN_TICKET = 'earn',
}

export enum REG_FLOW {
  SECOND_REG = 'second_registration',
  POST_REG_TASKS = 'post_registration',
  POPUNDER = 'popunder',
  PROMOTION = 'promotion',
  POST_REG_SURVEY = 'post_survey',
  PROMOTION_DATING = 'promotion_dating',
}

export enum SMARTLOOK_CUSTOM_EVENT {
  GOOGLE_AUTH = 'google_auth',
  SIGNUP_BUTTON_CLICK = 'sign_up_button_click',
  LOGOUT_BUTTON_CLICK = 'logout_button_click',
  MAIN_PAGE_IMPRESSION = 'main_page_impression',
  OFFER_CLICK = 'offer_click',
  BUY_SWEEP_CLICK = 'buy_sweep_click',
  WHEEL_CLICK = 'wheel_click',
  EARN_TICKET_CLICK = 'earn_ticker_click',
  ELAPSED_TIME = 'elapsed_time_less_7',
  SECOND_REGISTRATION_LOADED = 'second_registration',
  REG_PROMOTION_LOADED = 'reg_promotion',
  POST_REGISTRATION_LOADED = 'post_registration',
}

export enum PLACES_API_ADDRESS_COMPONENTS {
  CITY = 'locality',
  POST_CODE = 'postal_code',
  STREET = 'route',
  BUILDING = 'street_number',
}

export enum PLACES_API_PLACE_FIELDS {
  NAME = 'name',
  ADDRESS = 'address_components',
}

export enum OwnSurveyQuestionAnswersType {
  RADIO = 1,
  DROPDOWN = 2,
  INPUT = 3,
}

export namespace BackEndProfile {
  export const REG_IMP = 'reg_imp_id';
  export const SUB_ID1 = 'sub_id_1';
  export const SUB_ID4 = 'sub_id_4';
}

export enum NOTIFICATION {
  BELL = 1,
  IPP = 2,
  MODAL = 3,
}

export enum GLOBAL_TASK {
  PREPOP = 'prepop',
  TUTORIAL = 'tutorial',
  PROMOTION = 'promotion',
  VERIFICATION = 'verification',
  SWEEP_TUTORIAL = 'sweep_tutorial',
  POST_REG_SURVEY = 'post_survey',
  POST_REG_TASKS = 'post_tasks',
  SECOND_REGISTRATION = 'second_registration',
  PROMOTION_DATING = 'promotion_dating',
}
