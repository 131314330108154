import { makeStyles, shorthands } from '@griffel/react';

export default makeStyles({
  dialog: {
    width: '100%',
    boxSizing: 'border-box',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    zIndex: 1000,
    ...shorthands.borderRadius('16px'),
  },
  backGround: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
  primaryButton: {
    ...shorthands.margin('16px', '0'),
  },
  rejectButton: {
    marginTop: '16px',
  },
  buttons: {
    color: 'rgb(109, 110, 129)',
    '@media screen and (max-width: 500px)': {
      fontSize: '12px',
      lineHeight: 1,
      textAlign: 'justify',
    },
  },
});
