import Box from 'src/rewardis-kit/components/box';
import useRequest from 'src/hooks/useRequest';
import Response from 'src/components/Response';
import { RemoteDataResult } from 'src/lib/remoteData';
import { useLocaleState } from 'src/providers/LocaleProvider';
import useClasses from './get-styles';

interface DocumentPageProps {
  service: (lang: string) => Promise<RemoteDataResult<Page>>;
}

export default function DocumentPage({ service }: DocumentPageProps) {
  const locale = useLocaleState();
  const [request] = useRequest(async () => await service(locale));
  const styles = useClasses();

  return (
    <Response request={request}>
      {(data) => (
        <Box
          className={styles.wrapper}
          dangerouslySetInnerHTML={{
            __html: data.text,
          }}
        />
      )}
    </Response>
  );
}
