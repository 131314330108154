import { FieldName } from 'src/components/forms/RegistationForm/typings';
import { isModalTC, LANDING_ID } from 'src/constants/landings';

export const defaultFields: FieldName[] = [
  'first_name',
  'last_name',
  'email',
  'birth_date',
  'gender',
  'captchaV2',
  'captchaV3',
  'privacy_agreement',
];

export const shortFormFields: FieldName[] = [
  'email',
  'captchaV2',
  'captchaV3',
  'privacy_agreement_modal',
];

export const modalAgreementFields: FieldName[] = [
  'first_name',
  'last_name',
  'email',
  'birth_date',
  'gender',
  'captchaV2',
  'captchaV3',
  'privacy_agreement_modal',
];

export const biwardDefaultFields: FieldName[] = [
  'password',
  'email',
  'captchaV2',
  'captchaV3',
  'privacy_agreement',
];

export const getFieldsByDesignID = (designId: number) => {
  if (designId === LANDING_ID.SHORT_FORM) {
    return shortFormFields;
  }
  if (isModalTC(designId)) {
    return modalAgreementFields;
  }

  return defaultFields;
};

export const shortFieldsInitialValues: PartialRecord<
  FieldName,
  string | number | boolean
> = {
  gender: 1,
  first_name: 'first name',
  last_name: 'last name',
  birth_date: '1980-01-01',
};
