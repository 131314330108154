import * as Sentry from '@sentry/browser';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from 'rewardis/App';
import { setupYandexMetrika } from 'src/lib/extraScripts';

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: `${import.meta.env.VITE_SENTRY_DSN}`,
    release: 'rewardis-frontend',
    tracesSampleRate: 1.0,
    ignoreErrors: [
      'Non-Error promise rejection captured with value: Timeout',
      'Non-Error promise rejection captured with value: XhrError',
      /AbortError/,
    ],
    allowUrls: [new URL(window.location.toString()).origin],
    denyUrls: [/^chrome:\/\//i, /^chrome-extension:\/\//i],
  });
  Sentry.addTracingExtensions();
}

if (import.meta.env.VITE_YM_ID) {
  setupYandexMetrika(import.meta.env.VITE_YM_ID);
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <App />
);
