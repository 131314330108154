import { makeStyles, shorthands } from '@griffel/react';

export default makeStyles({
  fieldRow: {
    ...shorthands.margin('1rem', '0'),

    '@media (max-width: 900px)': {
      ...shorthands.margin('0.5rem', '0'),
    },
  },
  separator: {
    width: '100%',
    ...shorthands.margin('1.5rem', '0'),
    textAlign: 'center',
    position: 'relative',
    fontSize: '16px',
    textTransform: 'lowercase',

    '@media (max-width: 900px)': {
      ...shorthands.margin('0.5rem', '0'),
    },

    // [theme.breakpoints.up('sm')]: { // TODO: need to add breakpoint
    //   display: 'none',
    // },
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '50%',
      width: 'calc(50% - 25px)',
      height: '1px',
      // background: theme.palette.text.disabled, // TODO: need to add palette
    },
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      width: 'calc(50% - 25px)',
      right: 0,
      top: '50%',
      height: '1px',
      // background: theme.palette.text.disabled,
    },
  },
  mobileGoogleButton: {
    ...shorthands.margin('0', '0', '1rem'),
    width: '100%',

    '@media (max-width: 900px)': {
      ...shorthands.margin('0.5rem', '0', '0.5rem', '0'),
    },
  },
  field: {
    display: 'flex',
    ...shorthands.gap('0.75rem'),
    // [theme.breakpoints.down('sm')]: {  // TODO: need to add palette
    //   gap: 1,
    // },

    '@media (max-width: 900px)': {
      ...shorthands.gap('0.25rem'),
    },
  },
  phonePostCode: {
    ...shorthands.margin('0.5rem', '0'),
  },
  buttonWrapper: {
    display: 'flex',
    ...shorthands.gap('0.5rem'),
    ...shorthands.margin('1rem', '0', '0', '0'),
    flexWrap: 'wrap',

    '& button': {
      ...shorthands.flex('0', 'auto'),
      flexBasis: '100%',
    },
  },
});
