import { makeStyles, shorthands } from '@griffel/react';

export default makeStyles({
  footerWrapper: {
    backgroundColor: 'rgb(7, 9, 29)',
    maxWidth: '100%',
    width: '100%',
    marginTop: '32px',
    paddingY: '8px',
    ...shorthands.flex('0', 'auto'),
  },
  footerContainer: {},
  footerContent: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    paddingBottom: '8px',
    typography: 'body1',
  },
  footerLink: {
    color: 'rgb(255, 255, 255)',
    fontWeight: 300,
    marginRight: '16px',
    marginLeft: '16px',
    marginTop: '16px',
    '@media (min-width: 900px)': {
      ml: 0,
      marginTop: '8px',
    },
    '&:hover': {
      color: 'rgb(189,189,189)',
    },
    '&:visited': {
      color: 'rgb(189,189,189)',
    },
  },
  footerText: {
    color: 'rgb(255, 255, 255)',
    textAlign: 'center',
    marginTop: '8px',
    paddingTop: '8px',
    ...shorthands.borderTop('1px', 'solid', '#353647'),
    opacity: 0.5,
    display: 'block',
  },
});
