import React, { useCallback } from 'react';
import { CoreSelectorProps, Option } from './types';
import { CoreSelector } from './core-selector';

export const SingleSelector = <T,>({
  onChange,
  ...props
}: CoreSelectorProps<T>) => {
  const handleOptionClick = useCallback(
    (option: Option<T>) => {
      onChange?.([option]);
    },
    [onChange]
  );

  return <CoreSelector {...props} handleOptionClick={handleOptionClick} />;
};
