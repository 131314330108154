import { makeStyles, shorthands } from '@griffel/react';

export default makeStyles({
  container: {
    boxSizing: 'border-box',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    alignItems: 'center',
    ...shorthands.padding('24px', '16px'),
  },
});
