import { useIntl } from 'react-intl';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import Box from 'src/rewardis-kit/components/box';
import Typography from 'src/rewardis-kit/components/typography';

import { isFailure, isSuccess } from 'src/lib/remoteData';
import * as AuthService from 'src/services/AuthService';
import { sendAuditory } from 'src/services/AuthService';
import * as PageService from 'src/services/PageService';
import RegistrationForm from 'src/components/forms/RegistationForm/';
import { createFields } from 'src/components/forms/RegistationForm/utils';
import { setToken } from 'src/services/TokenService';
import { useLocaleState } from 'src/providers/LocaleProvider';
import DocumentPage from 'src/pages/documents/_DocumentPage';
import RegistrationPartnerModal from '../RegistrationPartnerModal';
import RegistrationBackButtonModal from '../RegistrationBackButtonModal';
import Modal from 'src/components/Modal';
import { getAuditoryId } from 'src/constants/auditories';
import { useRedirectToPopUnder } from 'src/providers/RegPopunderProvider';
import {
  useGoogleAuthDispatch,
  useGoogleAuthState,
} from 'src/providers/GoogleAuthProvider';
import GoogleButton from 'src/components/buttons/GoogleButton';
import { GOOGLE_BUTTON_POSITION, LANDING_ID } from 'src/constants/landings';
import useBlackListOfNames from 'src/hooks/useBlackListOfNames';
import useRegPartner from 'src/hooks/useRegPartner';
import { ErrorContainer } from 'src/lib/errors';
import { sendError } from 'src/services/ErrorService';
import ErrorsWrapper, { ErrorsData } from '../ErrorsWrapper';
import { useFeature } from 'src/providers/FeaturesProvider';
import { FEATURE, SMARTLOOK_CUSTOM_EVENT } from 'src/constants/enums';
import { useDesignParams } from 'src/hooks/useDesign';
import {
  SMARTLOOK_ELAPSED_TIME,
  smartlookTrackEvent,
} from 'src/services/GoogleTagManagerService';
import { buildUrlWithParams } from 'src/pages/registration/utils';
import { registrationParamsState } from 'src/recoil/registrationParams';
import {
  getGoogleButtonPosition,
  isHideGoogleAuthButton,
} from 'src/constants/landings';
import {
  getFieldsByDesignID,
  shortFieldsInitialValues,
} from 'src/components/forms/RegistationForm/contstants';
import useClasses from './get-styles';
import { mergeClasses } from '@griffel/react';
import { TypographyVariants } from 'src/rewardis-kit/components/typography/types';
import { recaptchaFullStateSelector } from 'src/recoil/recaptcha';
import * as Sentry from '@sentry/react';

enum ErrorsType {
  FORM,
  RESPONSE,
}

export default function StepRegistration({
  onNext,
  redirectUrl,
  registrationParams,
}: {
  onNext: (auth: boolean) => Promise<void>;
  redirectUrl?: string;
  registrationParams: Partial<RegistrationParams>;
}) {
  const styles = useClasses();
  const { oaid } = useRecoilValue(registrationParamsState);
  const intl = useIntl();
  const [recaptchaVersion, setRecaptchaVersion] = useState<RecaptchaVersion>(3);
  const [errors, setErrors] = useState<ErrorsData>({
    data: {},
  });
  const { profile: googleProfile } = useGoogleAuthState();

  const { designId } = useDesignParams();
  const { login } = useGoogleAuthDispatch(
    [LANDING_ID.HIDE_GOOGLE_AUTH_FULL_DATA, 69].includes(designId)
  );
  const locale = useLocaleState();

  // modal behavior
  const [isRegistrationPartnerModalOpen, setRegistrationPartnerModalOpen] =
    useState<boolean>(false);
  const [isPrivacyModalOpen, setPrivacyModalOpen] = useState<boolean>(false);
  const [isTermsModalOpen, setTermsModalOpen] = useState<boolean>(false);
  const { selectedPartnerIds, onPartnerSelect, partnerRequest } =
    useRegPartner(locale);
  const [isBackButtonModalOpen, setBackButtonModalOpen] =
    useState<boolean>(false);
  const redirectToRegPopUnder = useRedirectToPopUnder();

  const blacklist = useBlackListOfNames();
  const context = useRecoilValue(recaptchaFullStateSelector);

  const [timestampFirstInteraction, setTimestampFirstInteraction] = useState<
    number | null
  >(null);
  const onFirstInteraction = useCallback(
    (timestamp: number) => setTimestampFirstInteraction(timestamp),
    []
  );

  const classTextColor =
    Object.values(LANDING_ID).includes(Number(designId)) &&
    designId ?
    styles.withWhiteText : undefined;

  // handlers
  const handleSubmit = useCallback(
    async (data: any, { setSubmitting }: any) => {
      const { captchaV2, captchaV3, ...formData } = data;

      if (redirectUrl) {
        AuthService.RegFormStorageService.data = data;
        redirectToRegPopUnder(redirectUrl);

        return;
      }

      try {
        // TODO don't work with popunder
        const elapsedTime = timestampFirstInteraction
          ? new Date().getTime() - timestampFirstInteraction
          : undefined;

        if (elapsedTime && elapsedTime < SMARTLOOK_ELAPSED_TIME) {
          smartlookTrackEvent(SMARTLOOK_CUSTOM_EVENT.ELAPSED_TIME);
        }

        const { city, chosen_prize, ...restFormData } = formData;
        const { features, components, ...regParams } = registrationParams;

        if (!captchaV3) {
          Sentry.captureException('empty captchaV3 token', (scope) => {
            scope.setExtra('form values', data);
            scope.setExtra('captcha context', context);
            return scope;
          });
        }
        const signupRequest = await AuthService.signup(captchaV2, captchaV3, {
          ...restFormData,
          ...regParams,
          city: chosen_prize,
          sub_id6: googleProfile ? 'use_google_auth' : undefined,
          partners: selectedPartnerIds,
          has_metric: false,
          registration_url: window.location.href,
          referer_url:
            document.referrer || window.frames?.top?.document.referrer,
          elapsed_time: elapsedTime,
        });

        // @TODO: refactor
        if (isSuccess(signupRequest)) {
          const { auth, ...token } = signupRequest.data;

          await sendAuditory(
            getAuditoryId(formData['gender'], formData['birth_date'])
          );
          setToken(token);
          await onNext(!!auth);
        } else if (isFailure(signupRequest)) {
          if (signupRequest.error?.response?.status === 428) {
            // @TODO: refactor
            setRecaptchaVersion(2);
          } else {
            setErrors({ type: ErrorsType.RESPONSE, data: signupRequest.error });
          }
        }
        setSubmitting(false);
      } catch (err) {
        setSubmitting(false);
        console.warn(err);
      }
    },
    [
      redirectUrl,
      selectedPartnerIds,
      registrationParams,
      googleProfile,
      timestampFirstInteraction,
    ]
  );

  const handleErrorsChange = useCallback(
    (errors: Record<string, string>, errorContainer?: ErrorContainer) => {
      if (errorContainer) {
        sendError(errorContainer, registrationParams?.reg_imp_id ?? 0);
      }
      setErrors({ type: ErrorsType.FORM, data: errors });
    },
    [registrationParams.reg_imp_id]
  );

  const handleTermsClick = useCallback(() => setTermsModalOpen(true), []);
  const handlePrivacyClick = useCallback(() => setPrivacyModalOpen(true), []);
  const handleMarketingClick = useCallback(
    () => setRegistrationPartnerModalOpen(true),
    []
  );

  const openBackButtonModal = useCallback(
    () => setBackButtonModalOpen(true),
    []
  );

  const featureBackRegPopup = useFeature(FEATURE.BACK_REG_POPUP);

  const handleBackButtonModalClick = useCallback(() => {
    if (!oaid) return;
    const additionalParams = {
      oaid,
      var: registrationParams.sub_id1!,
      ymid: registrationParams.sub_id2!,
      ab2r: registrationParams.ab2r,
      abtest: registrationParams.ab2r,
    };
    const currentTabLink = buildUrlWithParams(
      featureBackRegPopup?.current_tab_link!,
      additionalParams
    );
    const newTabLink = buildUrlWithParams(
      featureBackRegPopup?.new_tab_link!,
      additionalParams
    );

    if (newTabLink) {
      const w = window.open(newTabLink, '_blank');

      if (w && currentTabLink) {
        window.location.replace(currentTabLink);
      }
    }
  }, [featureBackRegPopup, registrationParams, oaid]);

  const backButtonHijack = () => {
    for (let i = 0; i < 10; i += 1) {
      window.history.pushState(null, '');
    }

    window.addEventListener('popstate', () => {
      openBackButtonModal();
    });
  };

  useEffect(() => {
    if (featureBackRegPopup) {
      backButtonHijack();
    }
  }, [featureBackRegPopup]);

  return (
    <>
      <Box className={mergeClasses(styles.wrapper, 'form-title')}>
        {designId === 7 && (
          <Typography variant={TypographyVariants.h5}>
            {intl.formatMessage({
              id: 'RegistrationPage.createAccount',
              defaultMessage: 'Create an account',
            })}
          </Typography>
        )}
        {[LANDING_ID.OLD, LANDING_ID.OLD_FAST].includes(designId) && (
          <GoogleButton onClick={login} />
        )}
      </Box>

      <RegistrationPartnerModal
        partnerRequest={partnerRequest}
        selectedPartnerIds={selectedPartnerIds}
        onPartnerSelect={onPartnerSelect}
        handleClose={() => setRegistrationPartnerModalOpen(false)}
        isOpen={isRegistrationPartnerModalOpen}
      />

      <RegistrationBackButtonModal
        handleClick={handleBackButtonModalClick}
        handleClose={() => setBackButtonModalOpen(false)}
        isOpen={isBackButtonModalOpen}
      />

      <ErrorsWrapper errors={errors} />

      <Modal
        fullWidth
        withClose
        isOpen={isPrivacyModalOpen}
        handleClose={() => setPrivacyModalOpen(false)}
      >
        <DocumentPage service={PageService.getPrivacyPage} />
      </Modal>

      <Modal
        fullWidth
        withClose
        isOpen={isTermsModalOpen}
        handleClose={() => setTermsModalOpen(false)}
      >
        <DocumentPage service={PageService.getTermsPage} />
      </Modal>

      <Box
        className={mergeClasses(styles.registrationFormWrapper, classTextColor)}
      >
        <RegistrationForm
          designId={designId}
          abExperimentId={registrationParams.ab_experiment_id}
          fields={createFields(
            getFieldsByDesignID(designId),
            registrationParams.ab_experiment_id
          )}
          extInitialValues={
            designId === LANDING_ID.SHORT_FORM ? shortFieldsInitialValues : {}
          }
          mobileGoogleButtonPosition={
            googleProfile && isHideGoogleAuthButton(designId)
              ? GOOGLE_BUTTON_POSITION.NONE
              : getGoogleButtonPosition(designId)
          }
          blacklist={blacklist}
          recaptchaVersion={recaptchaVersion}
          handleSubmit={handleSubmit}
          handleErrorsChange={handleErrorsChange}
          handleTermsClick={handleTermsClick}
          handlePrivacyClick={handlePrivacyClick}
          handleMarketingClick={handleMarketingClick}
          onFirstInteraction={onFirstInteraction}
        />
      </Box>
    </>
  );
}
