import { IntlShape } from 'react-intl';
import {
  createRange,
  CURRENT_YEAR,
  daysInMonth,
  MIN_AGE_LIMIT,
} from 'src/lib/date';
import { Option } from 'src/rewardis-kit/components/core-selector';

export const createMonthRange = (intl: IntlShape) => {
  const months = [
    { value: 1, days: 31, id: 'date.january', defaultMessage: 'January' },
    { value: 2, id: 'date.february', defaultMessage: 'February' },
    { value: 3, id: 'date.march', defaultMessage: 'March' },
    { value: 4, id: 'date.april', defaultMessage: 'April' },
    { value: 5, id: 'date.may', defaultMessage: 'May' },
    { value: 6, id: 'date.june', defaultMessage: 'June' },
    { value: 7, id: 'date.july', defaultMessage: 'July' },
    { value: 8, id: 'date.august', defaultMessage: 'August' },
    { value: 9, id: 'date.september', defaultMessage: 'September' },
    { value: 10, id: 'date.october', defaultMessage: 'October' },
    { value: 11, id: 'date.november', defaultMessage: 'November' },
    { value: 12, id: 'date.december', defaultMessage: 'December' },
  ];

  return months.map(({ value, days, id, defaultMessage }) => ({
    value,
    days,
    label: intl.formatMessage({ id, defaultMessage }),
  }));
};

export const date = new Date();

export const CURRENT_MONTH = date.getMonth();
export const CURRENT_DAY = date.getDate();

export const YEAR_FROM = 1920;
export const YEAR_TO = CURRENT_YEAR - MIN_AGE_LIMIT;

export const createYearsMenuItems = (): Option<number>[] =>
  createRange(YEAR_TO, YEAR_FROM).map((year) => ({
    value: year,
    id: year.toString(),
    label: year.toString(),
  }));

export const createMonthMenuItems = (intl: IntlShape): Option<number>[] =>
  createMonthRange(intl).map(({ value, label }) => ({
    value,
    label,
    id: value.toString(),
  }));

export const createDayMenuItems = (
  year: number,
  month: number
): Option<number>[] =>
  createRange(1, daysInMonth(year, month)).map((day) => ({
    value: day,
    id: day.toString(),
    label: day.toString(),
  }));

export const parseBirthDate = (value: string = '') => {
  const [year, month, day] = value.split('-').map((s) => parseInt(s, 10));
  if (day) {
    return { year, month, day };
  }

  return { year: YEAR_TO, month: CURRENT_MONTH + 1, day: CURRENT_DAY };
};

export const prepareBirthDate = (
  birthDateDay: number,
  birthDateMonth: number,
  birthDateYear: number
): string => {
  const day = birthDateDay < 10 ? '0' + birthDateDay : birthDateDay;
  const month = birthDateMonth < 10 ? '0' + birthDateMonth : birthDateMonth;

  return `${birthDateYear}-${month}-${day}`;
};
