import axios from 'axios';
import { formatDateForParser } from 'src/lib/date';

export type TaboolaBannerData = {
  name: string;
  thumbnail: {
    url: string;
  }[];
  type: string;
  url: string;
};

export type TaboolaApiBannerResponse = {
  list: {
    name: string;
    thumbnail: {
      url: string;
    }[];
    type: string;
    url: string;
  }[];
};

class TaboolaService {
  private installed: boolean = false;

  constructor() {}

  init() {
    if (this.installed) {
      return;
    }
    this.installed = true;
    window._taboola = window._taboola || [];
    window._taboola.push({ article: 'auto' });

    (function (e, f, u, i) {
      if (!document.getElementById(i)) {
        e.async = true;
        e.src = u;
        e.id = i;
        f.parentNode?.insertBefore(e, f);
      }
    })(
      document.createElement('script'),
      document.getElementsByTagName('script')[0],
      '//cdn.taboola.com/libtrc/rewardisonline/loader.js',
      'tb_loader_script'
    );

    if (window.performance && typeof window.performance.mark == 'function') {
      window.performance.mark('tbl_ic');
    }
  }
  installHorizontalWidget(container: string) {
    window._taboola = window._taboola || [];
    window._taboola.push({
      mode: 'thumbnails-a-stream',
      container,
      placement: 'Below Article Stream Thumbnails',
      target_type: 'mix',
    });
  }

  flush() {
    window._taboola = window._taboola || [];
    window._taboola.push({ flush: true });
  }

  async getBanner(profile: Profile) {
    const { sub_id_1, geo, os_id, ip, id } = profile;

    const { data } = await axios.get<TaboolaApiBannerResponse>(
      'http://api.taboola.com/1.2/json/propellerads-infox/recommendations.get',
      {
        params: {
          app: {
            type: 'desktop',
            apikey: '6c94a24f3b6605ee46ad2ac36d7844a5153f4848',
            s2s: true,
          },
          placement: {
            'rec-count': 1,
            name: `z${sub_id_1}zb${sub_id_1}bc${geo}cp${os_id}ph${formatDateForParser(new Date())}00h`,
            visible: false,
          },
          source: {
            url: 'https://rewardis.online',
            type: 'home',
            id: sub_id_1,
          },
          user: {
            session: 'init',
            agent: navigator.userAgent,
            id: id,
            realip: ip,
          },
        },
        paramsSerializer: {
          encode: (params, value) => {
            if (params.toString().includes('[')) {
              return params.replace('[', '.').replace(']', '');
            }
            return params;
          },
        },
      }
    );

    return data.list[0];
  }
}

export default new TaboolaService();
