import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { registrationParamsGetState } from 'src/recoil/registrationParams';
import { useMessage } from 'src/providers/WebsocketProvider';
import { MESSAGE_LOAD_PIXEL } from 'src/providers/WebsocketUserMessages';
import * as PixelSerivce from 'src/services/PixelService';
import { useProfileState } from 'src/providers/ProfileProvider';

import * as Sentry from '@sentry/browser';
import {PIXEL_IFRAME_ERROR, PIXEL_IFRAME_WARNING} from "src/constants/app";

const usePixelHTML = () => {
  const regParams = useRecoilValue(registrationParamsGetState);
  const { profile } = useProfileState();

  const loadPixelForSignUp = async () => {
    if (
      profile ||
      !regParams.reg_imp_id ||
      !regParams.sub_id1 ||
      !regParams.sub_id4
    ) {
      return;
    }
    await PixelSerivce.loadPixelForSignUp(
      regParams.reg_imp_id,
      regParams.sub_id1,
      regParams.sub_id4
    );
  };

  const loadPixelForAuthed = async (type: number) => {
    await PixelSerivce.getPixelForAuthed(type);
  };

  useEffect(() => {
    loadPixelForSignUp();
  }, [regParams]);

  useEffect(() => {
    const messageHandler = (event: MessageEvent) => {
      if (event?.data?.type === PIXEL_IFRAME_ERROR) {
        console.error('Error in iframe:', event.data);
        Sentry.captureException(new Error(event.data.message), {
          extra: {
            source: event.data.source,
            lineno: event.data.lineno,
            colno: event.data.colno,
            error: event.data.error,
          },
        });
      } else if (event?.data?.type === PIXEL_IFRAME_WARNING) {
        console.warn('Warning in iframe:', event.data.message);
        Sentry.captureMessage(event.data.message, {
          level: 'warning',
        });
      }
    };

    window.addEventListener('message', messageHandler, false);
    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, []);

  useMessage(MESSAGE_LOAD_PIXEL, (data: Record<string, number>[]) => {
    const { type } = data[0];

    loadPixelForAuthed(type);
  });
};

export default usePixelHTML;
