import { useEffect, useCallback } from 'react';
import { useLocaleState } from 'src/providers/LocaleProvider';
import { useRecoilValue } from 'recoil';
import {
  recaptchaFullStateSelector,
  useRecaptchaMutation,
} from 'src/recoil/recaptcha';

declare global {
  interface Window {
    GoogleReCaptcha_onload?: () => void;
  }
}

const scriptDataId: string = 'rewardis-script-recaptcha-v3';
const styleDataId: string = 'rewardis-style-recaptcha-v3';

interface useReCaptachaLoaderProps {
  locale: string;
  siteKeyV3: string;
}

const useReCaptchaLoader = (props: useReCaptachaLoaderProps) => {
  const { locale = 'en', siteKeyV3 = 'explicit' } = props;

  const isScriptLoaded = () =>
    document.querySelector(`script[data-id="${scriptDataId}"]`) !== null;

  return {
    load: (onLoadCallback: () => void) => {
      const setupOnLoadCallback = () => {
        window.GoogleReCaptcha_onload = () => {
          onLoadCallback();
          delete window.GoogleReCaptcha_onload;
        };
      };

      const loadReCaptchaScript = () => {
        const script: HTMLScriptElement = document.createElement('script');
        script.setAttribute('data-id', scriptDataId);
        script.src = `https://www.google.com/recaptcha/api.js?render=${siteKeyV3}&onload=GoogleReCaptcha_onload&hl=${locale}`;
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
      };

      if (!isScriptLoaded()) {
        setupOnLoadCallback();
        loadReCaptchaScript();
      } else if (window.GoogleReCaptcha_onload) {
        setupOnLoadCallback();
      } else {
        onLoadCallback();
      }
    },
  };
};


function showRecaptchaBadge() {
  const element = document.querySelector(`style[data-id="${styleDataId}"]`);

  if (element) {
    element.remove();
  }
}

function hideRecaptchaBadge() {
  const element = document.querySelector(`style[data-id="${styleDataId}"]`);

  if (element) {
    return;
  }

  const style: HTMLStyleElement = document.createElement('style');
  style.innerHTML = '.grecaptcha-badge { display: none !important; }';
  style.setAttribute('data-id', styleDataId);
  document.body.appendChild(style);
}

export function useRecaptchaBadge() {
  useEffect(() => {
    showRecaptchaBadge();

    return () => {
      hideRecaptchaBadge();
    };
  }, []);
}

export function useReCaptchaContext() {
  const context = useRecoilValue(recaptchaFullStateSelector);
  const mutateRecaptcha = useRecaptchaMutation();
  const locale = useLocaleState();

  const { load } = useReCaptchaLoader({
    locale,
    siteKeyV3: context.siteKeyV3 ?? '',
  });

  const handleUpdateLoaded = () => {
    if (!context.loaded) {
      load(() => {
        mutateRecaptcha({ loaded: true });
      });
    }
  };

  useEffect(() => {
    handleUpdateLoaded();
  }, []);

  return context;
}
