import { makeStyles } from '@griffel/react';

export const useClasses = makeStyles({
  dropdown: {
    display: 'block',
    position: 'absolute',
    left: 0,
    top: 'calc(100% + 0.5rem)',
    width: ' 100%',
    transform: ' translateY(-1rem)',
    opacity: 0,
    visibility: 'hidden',
    zIndex: 10,
  },
  withOpen: {
    opacity: 1,
    visibility: 'visible',
    transform: 'translateY(0)',
  },
});
